import { httpClient } from '..';

export const subscribeToNews = async (email: string) => {
  const data = await httpClient.post(`/newsletters`, {
    data: { email },
    headers: {
      'Content-Type': 'application/json',
    },
  });
  return data;
};
