import { httpClient } from '..';

import {
  QuiltsResponseMega,
  TQuiltSearchRequest,
  TQuiltType,
  TSearchQuiltResponse,
} from '../types';

export const fetchPublicQuiltWithMemorial = async (id: string | number) => {
  const data = await httpClient.get(`quilts/${id}/shared/public`);
  return data;
};

export const editUnpublishedQuilt = async (id: string): Promise<any> => {
  const { data } = await httpClient.get(`/quilts/${id}`);

  if (!data?.returned?.id) return;

  const quiltForEditing = {
    id: data.returned.id,
    access: data?.returned?.access || '',
    ...(data?.returned?.firstName && { firstName: data.returned.firstName }),
    ...(data?.returned?.lastName && { lastName: data.returned.lastName }),
    ...(data?.returned?.dateOfBirth && { dateOfBirth: data.returned.dateOfBirth }),
    ...(data?.returned?.dateOfPassing && { dateOfPassing: data.returned.dateOfPassing }),
    ...(data?.returned?.finalImage && { finalImage: data.returned.finalImage }),
    ...(data?.returned?.placesOfResidence && {
      placesOfResidence: data.returned.placesOfResidence,
    }),
    ...(data?.returned?.educations && { educations: data.returned.educations }),
    ...(data?.returned?.hobbiesAndPasttimes && {
      hobbiesAndPasttimes: data.returned.hobbiesAndPasttimes,
    }),
    ...(data?.returned?.communityInvolvements && {
      communityInvolvements: data.returned.communityInvolvements,
    }),
    ...(data?.returned?.hometown && { hometown: data.returned.hometown }),
    ...(data?.returned?.placeOfBurial && { placeOfBurial: data.returned.placeOfBurial }),
    ...(data?.returned?.inMemorialDonation && {
      inMemorialDonation: data.returned.inMemorialDonation,
    }),
    ...(data?.returned?.donationLink && { donationLink: data.returned.donationLink }),
    ...(data?.returned?.memorial && { memorial: data.returned.memorial }),
    ...(data?.returned?.isEnableTributesAndCondolence && {
      isEnableTributesAndCondolence: data.returned.isEnableTributesAndCondolence,
    }),
    ...(data?.returned?.isPublic && { isPublic: data.returned.isPublic }),
    // ...(data?.returned?.memorial?.isPublic && {
    //   memorialIsPublic: data.returned.memorial.isPublic,
    // }),
    // ...(data?.returned?.memorial?.isPublished && {
    //   memorialIsPublished: data.returned.memorial.isPublished,
    // }),
    isPublished: data.returned?.isPublished,
    isPublic: data.returned?.isPublic,
    ...(data?.returned?.album && { album: data.returned.album  }),
    isOwner: data.returned?.isOwner,
    isContributor: data.returned?.isContributor,
    isInvitedUser: data.returned?.isInvitedUser || false
  };

  return quiltForEditing;
};

export const getMemorial = async (id: string): Promise<any> => {
  const { data } = await httpClient.get(`/quilts/${id}`);

  return data.returned.memorial || '';
};

export const searchQuilts = async (
  search: TQuiltSearchRequest
): Promise<TSearchQuiltResponse[]> => {
  const { data } = await httpClient.post('/quilts/search', {
    data: search,
  });
  return data.returned;
};

export const getSimpleQuilt = async (id: string) => {
  const { data } = await httpClient.get(`/quilts/${id}/simple`);
  return data.returned as TQuiltType;
};

export const getQuiltsMega = async (limit: number) => {
  const { data } = await httpClient.get(`/quilts/mega`, {
    params: {
      limit,
    },
  });

  return data.returned as QuiltsResponseMega[];
};
