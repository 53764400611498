import React, { FC, useEffect, useState } from 'react';
import {useParams, useLocation, useSearchParams} from 'react-router-dom';
import {  Box } from '@mui/material';

import { useAuth } from 'providers/auth-provider';
import { useTranslates } from 'providers/translates';
import { RoutesName } from 'routs';
import { DefaultDialog } from '../DefaultDialog';

import './header.scss';
import {useAppSelector, useRedirectAndScroll} from "../../hooks";
import {getCookie} from "../../utilities";

interface HeaderProps {}

const initialHeaderItemsController = {
  language: false,
  howToUse: false,
  support: false,
  myAccount: false,
  donate: false,
  aboutUs: false,
  contactUs: false,
  icons: false,
};

const Header: FC<HeaderProps> = () => {
  const isSave = useAppSelector(state => state.notify.isSave)
  const { onlyRedirect } = useRedirectAndScroll({})
  const { id } = useParams();
  const [searchParams] = useSearchParams();
  const { isAuthorized, user, logout } = useAuth();
  const { selectedLang, setSelectedLang, translates } = useTranslates();
  const location = useLocation();

  const [open, setOpen] = useState(false);
  const [headerItemsController, setHeaderItemsController] = useState(initialHeaderItemsController);

  // logic for inviting users
  useEffect(() => {
    if (user?.isInvitedUser) {
      const quiltId = getCookie("quiltId");
      const step = searchParams.get('step');
      const allowPages = ["login", "signup", "howtouse", "resources", "search"];

      if (!quiltId) return;

      const isCanRedirect = allowPages.some(page => location.pathname.includes(page) || location.pathname === '/');

      if (!step && !isCanRedirect) {
        onlyRedirect(`/update/${quiltId}?step=3`)
      }
    }
  }, [user?.isInvitedUser, location])

  useEffect(() => {
    if (!location?.pathname) return;

    if (user?.isInvitedUser) {
      setHeaderItemsController(() => {
        return {
          language: true,
          howToUse: true,
          support: true,
          myAccount: false,
          donate: false,
          aboutUs: false,
          contactUs: false,
          icons: false,
        };
      });
      return;
    }

    switch (location.pathname) {
      case '/':
        setHeaderItemsController(() => {
          return {
            language: true,
            howToUse: true,
            support: true,
            myAccount: true,
            donate: true,
            aboutUs: false,
            contactUs: false,
            icons: false,
          };
        });
        break;
      default:
        setHeaderItemsController(() => {
          return {
            language: true,
            howToUse: true,
            support: true,
            myAccount: true,
            donate: false,
            aboutUs: false,
            contactUs: false,
            icons: false,
          };
        });
    }
  }, [location]);

  const handleConfirmDialogOpen = () => {
    setOpen(true);
  };

  const handleConfirmDialogClose = () => {
    setOpen(false);
  };

  const goToNewQuilt = () => {
    user?.isInvitedUser ? onlyRedirect(RoutesName.SignUp) : onlyRedirect(`${RoutesName.CreateQuilt}?step=1`);
    handleConfirmDialogClose();
    window.location.reload();
  };

  const actionLogout = () => {
    isSave && logout();
    onlyRedirect(location.pathname, true);
  }

  return (
    <header className="header">
      <div className="center">
        <div className="header__info">
          {headerItemsController.language && (
            <div className="header__lang">
              <span
                className="-active"
                onClick={() => setSelectedLang(selectedLang === 'en-CA' ? 'fr-CA' : 'en-CA')}
              >
                {selectedLang === 'en-CA' ? 'English' : 'Français'}
              </span>
            </div>
          )}

          {headerItemsController.aboutUs && (
            <div className="header__link" onClick={() => onlyRedirect('/howtouse')}>
              <span>{translates?.header?.about || 'About Us'}</span>
            </div>
          )}

          {headerItemsController.contactUs && (
            <a
              className="header__link"
              target={'_blank'}
              rel="noopener noreferrer"
              href="mailto:info@virtualhospice.ca?subject=I have questions about the MemorialQuilt"
            >
              <span>{translates?.header?.contact || 'Contact Us'}</span>
            </a>
          )}

          {headerItemsController.howToUse && (
            <div className="header__link" onClick={() => onlyRedirect('/howtouse')}>
              <span>{'How To Use'}</span>
            </div>
          )}

          {headerItemsController.support && (
            <div className="header__link" onClick={() => onlyRedirect('/resources')}>
              <span>{'Resources'}</span>
            </div>
          )}

          {isAuthorized && headerItemsController.myAccount && (
            <div className="header__link" onClick={() => onlyRedirect('/profile')}>
              <span>{'My Account'}</span>
            </div>
          )}

          {isAuthorized && !user?.isInvitedUser? (
            <div
              className="header__link"
              onClick={actionLogout}
            >
              <span>{translates?.header?.logout || 'Logout'}</span>
            </div>
          ) : (
            <div className="header__link" onClick={() => onlyRedirect('/login')}>
              <span>{translates?.header?.signIn || 'Sign In'}</span>
            </div>
          )}

          {headerItemsController.icons && (
            <div className="icon-links">
              <a
                className="link-item"
                href="https://www.facebook.com/pages/Canadian-Virtual-Hospice/119170451459541?ref=ts"
                target="__blank"
              >
                <i className="fa fa-facebook-f"></i>
              </a>
              <a className="link-item" href="https://twitter.com/VirtualHospice" target="__blank">
                <i className="fa fa-twitter"></i>
              </a>
              <a
                className="link-item"
                href="https://www.youtube.com/user/cvhcvcsp?ob=video-mustangbase"
                target="__blank"
              >
                <i className="fa fa-youtube"></i>
              </a>
              <a
                className="link-item"
                href="https://www.linkedin.com/company/canadian-virtual-hospice"
                target="__blank"
              >
                <i className="fa fa-linkedin"></i>
              </a>
            </div>
          )}
        </div>
      </div>

      <Box
        sx={{
          minHeight: '1px',
          background: "url('/img/general/line-small.png') repeat-x",
        }}
      ></Box>

      <div className="center">
        <div className="header__content">
          <div 
            onClick={() => onlyRedirect("/")} 
            className="logo" 
            style={{
              cursor: 'pointer'
            }}
          >
            <img src="/img/general/logo.png" alt="logo" />
          </div>
          <div className="header__wrapper">
            <div className="header__user">
              {/*<div className="mail">*/}
              {/*  {isAuthorized && (*/}
              {/*    <div onClick={() => onlyRedirect("/profile")} style={{ color: '#293267', cursor: 'pointer' }}>*/}
              {/*      {user!.userName}*/}
              {/*    </div>*/}
              {/*  )}*/}
              {/*</div>*/}
              {/* {isAuthorized ? (
                <button className="button header__button-auth -blue" onClick={() => {
                  logout()
                  navigate(location.pathname,{replace:true})
                  }}>
                  {translates?.header?.logout || 'Logout'}
                </button>
              ) : (
                <button className="button header__button-auth -green" onClick={() => navigate('/login')}>
                  {translates?.header?.signIn || 'Sign In'}
                </button>
              )} */}
            </div>

            <div className="header__buttons">
              <div>
                <span className="header__button" onClick={() => onlyRedirect(RoutesName.SearchQuilt)}>
                  {translates?.header?.searchQuilts || 'Search Quilts'}
                </span>
              </div>

              <div className="header__button-delimitiier">
                {location.pathname !== `/update/${id}` ? (
                  <span
                    className="header__button"
                    onClick={() => {
                      isAuthorized && !user?.isInvitedUser
                        ? onlyRedirect(`${RoutesName.CreateQuilt}?step=1`)
                        : onlyRedirect(RoutesName.SignUp);
                    }}
                  >
                    {translates?.header?.createQuilt || 'Create a quilt'}
                  </span>
                ) : (
                  <div>
                    <span onClick={handleConfirmDialogOpen} className="header__button">
                      {translates?.header?.createQuilt || 'Create a quilt'}
                    </span>

                    <DefaultDialog
                      open={open}
                      setDialog={handleConfirmDialogClose}
                      title={
                        translates?.header?.createANewQuiltConfirmDialogTitle ||
                        'Are you sure you want to exit the editable quilt?'
                      }
                      text={
                        translates?.header?.createANewQuiltConfirmDialogContent ||
                        'All unsaved changes may be lost!'
                      }
                      cancelBtnText={
                        translates?.header?.createANewQuiltConfirmDialogConfirmBtnLabel || 'Stay'
                      }
                      confirmBtnText={
                        translates?.header?.createANewQuiltConfirmDialogCancelBtnLabel ||
                        'New quilt'
                      }
                      confirmAction={goToNewQuilt}
                    />
                  </div>
                )}
              </div>
            </div>
          </div>
        </div>
      </div>
    </header>
  );
};

export default Header;
