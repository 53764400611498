import { FC } from 'react';

import { QuiltsResponseMega } from '../../../../api/http-client/types';
import { RowDesk } from './RowDesk';
import { RowTablet } from './RowTablet';

import { useMediaQuery } from '@mui/material';
import { DataSettingModel } from '../../../../hooks/useCarouselHome';
import { OptionsWrapEnum } from '../../index';

interface CarouselItemTypeModel {
  items: QuiltsResponseMega[];
  isReverse?: boolean;
  settingData: DataSettingModel;
}

interface MainCarouseRowProps extends CarouselItemTypeModel {
  indexOfRenderRow: number;
  option: OptionsWrapEnum;
}

const MainCarouseRow: FC<MainCarouseRowProps> = ({
  indexOfRenderRow,
  items,
  settingData,
  option,
}) => {
  const matches1240 = useMediaQuery('(max-width: 1240px)');

  if (matches1240) {
    return (
      <RowTablet
        indexOfRenderRow={indexOfRenderRow}
        items={items}
        settingData={settingData}
      ></RowTablet>
    );
  }

  return (
    <RowDesk
      option={option}
      indexOfRenderRow={indexOfRenderRow}
      items={items}
      settingData={settingData}
    ></RowDesk>
  );
};

export default MainCarouseRow;
