const handleKeyDown = (
    event: React.KeyboardEvent<HTMLDivElement>, 
    searchClick: () => void
) => {
    if (event.key === 'Enter') {
      searchClick();
    }
};

export { handleKeyDown };
