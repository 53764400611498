interface IUserTableHeader {
  id: string;
  label: string;
  width: string;
  align: 'inherit' | 'left' | 'center' | 'right' | 'justify';
}

export const UserTableHeader: IUserTableHeader[] = [
  {
    id: 'email',
    label: 'Name',
    width: '350px',
    align: 'left',
  },
  {
    id: 'dateCreated',
    label: 'Account created',
    width: 'auto',
    align: 'center',
  },
  {
    id: 'publishedQty',
    label: 'Published',
    width: 'auto',
    align: 'center',
  },
  {
    id: 'publicQty',
    label: 'Public',
    width: 'auto',
    align: 'center',
  },
  {
    id: 'quiltsQty',
    label: 'Quilts',
    width: 'auto',
    align: 'center',
  },
  {
    id: 'lockedQty',
    label: 'Locked',
    width: 'auto',
    align: 'center',
  },
  {
    id: 'draftQty',
    label: 'Draft',
    width: 'auto',
    align: 'center',
  },
  {
    id: 'deletedQty',
    label: 'Deleted',
    width: 'auto',
    align: 'center',
  },
  {
    id: 'button',
    label: '',
    width: 'auto',
    align: 'center',
  },
];

export enum SortBy {
  ASC = 'asc',
  DESC = 'desc',
}

export enum UserSortField {
  EMAIL = 'email',
  DATE_CREATED = 'dateCreated',
}
