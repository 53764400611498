import React, { ReactNode } from 'react';
import styles from "../../styles.module.scss"

interface CarouselItemProps {
	children: ReactNode;
}

export const CarouselItem: React.FC<CarouselItemProps> = ({ children }) => {
	return (
		<div className={styles.carousel__item}>{children}</div>
	);
};
