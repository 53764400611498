import { AnyAction } from '@reduxjs/toolkit';
import { Context, createContext, PropsWithChildren, useCallback, useState } from 'react';
import { createSelectorHook, Provider as ReduxProvider, ReactReduxContextValue } from 'react-redux';

import { authService, authStore, TAuthState } from '../../api/auth';
import { TLoginRequest, TRegisterRequest } from '../../api/http-client/types';
import { AuthContext } from './context';

interface Props {}

const storeContext = createContext(null) as unknown as Context<
  ReactReduxContextValue<TAuthState, AnyAction>
>;

const useSelector = createSelectorHook(storeContext);
const useAuthData = () => useSelector((state: TAuthState) => state);

const Provider = (props: PropsWithChildren<Props>) => {
  const authData = useAuthData();
  const [loading, setLoading] = useState(false);

  const login = useCallback(async (props: TLoginRequest) => {
    setLoading(true);

    return authService.login(props).then((res) => {
      setLoading(false);
      return res;
    });
  }, []);
  const logout = useCallback(() => authService.logout(), []);
  const register = (props: TRegisterRequest) => authService.register(props);
  const setUser = useCallback(() => {}, []);

  return (
    <AuthContext.Provider
      value={{
        login,
        logout,
        register,
        setUser,
        loading,
        ...authData,
      }}
    >
      {props.children}
    </AuthContext.Provider>
  );
};

export const AuthProvider = (props: PropsWithChildren<Props>) => (
  <ReduxProvider store={authStore} context={storeContext}>
    <Provider {...props} />
  </ReduxProvider>
);
