import React, { Dispatch, SetStateAction } from 'react';
import {
  Box, FormControlLabel,
  Stack, Switch,
  Table,
  TableBody,
  TableCell,
  TableContainer,
  TableHead,
  TableRow,
  useMediaQuery,
} from '@mui/material';

import { TProfile, TRemoveAccess, TShareQuilt, TUserQuilt } from 'api/http-client/profileTypes';

import {
  AccessPermissionsDisplayEnum,
  AccessPermissionsTextEnum,
} from 'components/CreateMemorialQuiltComponents/CreateMemorialQuiltStepTwo/const';
import { EditButton, ViewButton } from './TableButtons';
import { QuiltsTableRow } from './QuiltsTableRow';
import {
  myTableHeaderFields,
  myTableHeaderFieldsMobile,
  myTableHeaderFieldsTablet,
  sizeColumnInviteMemorials,
  sizeColumnInviteMemorialsMobile,
  sizeColumnMyMemorials,
  sizeColumnMyMemorialsMobile,
  sizeColumnMyMemorialsTablet,
  tableHeaderFields,
  tableHeaderFieldsMobile,
} from './const';

import styles from './styles.module.scss';
import {useRedirectAndScroll} from "../../hooks";
import {Link} from "react-router-dom";
import { DarkTooltip } from "../../components/Tooltips/CustomTooltips";
import { colors, errorBuilderPrivateDialog } from 'utilities';
import HelpIcon from '@mui/icons-material/Help';
import { DefaultDialog } from 'components/DefaultDialog';

interface MyQuiltsProps {
  quiltsList: TUserQuilt[] | null;
  user: TProfile;
  editableList: TUserQuilt[] | null;
  errorPrivateDialog: boolean;
  onSetErrorPrivatDialog: Dispatch<SetStateAction<boolean>>;
  publicUnpublic: (quilt: TUserQuilt) => void;
  publishUnpublish: (quilt: TUserQuilt) => void;
  shareQuilt: (quilt: TShareQuilt) => void;
  removeAccess: (data: TRemoveAccess) => void;
  deleteUserQuilt: (id: string) => void;
}

export const MyQuilts: React.FC<MyQuiltsProps> = ({
  quiltsList,
  user,
  editableList,
  errorPrivateDialog,
  onSetErrorPrivatDialog,
  publicUnpublic,
  publishUnpublish,
  shareQuilt,
  removeAccess,
  deleteUserQuilt,
}) => {
  const { onlyRedirect } = useRedirectAndScroll({})
  const matches_1400 = useMediaQuery('(min-width: 1400px)');
  const matches_900 = useMediaQuery('(min-width: 900px)');

  const editButtonClick = (value: TUserQuilt) => {
    switch (value.access) {
      case AccessPermissionsTextEnum.EDIT_IMAGES:
        onlyRedirect('/add-images/' + value.id);
        break;
      case AccessPermissionsTextEnum.EDIT_IMAGES_MEMORIAL:
        onlyRedirect('/update/' + value.id + '?step=3');
        break;
    }
  };

  const getStatusWithTooltipOrOtherItem = (e: string) => {
    return e === 'Status' ? (
      <Box 
        sx={{ display: 'flex', alignItems: 'center', gap: '16px' }}
      >
        <span className="defaultTitle1">{e}</span>
        <DarkTooltip
          title={'Quilt owners can change the status.'}
        >
          <HelpIcon sx={{ color: colors.blue }} />
        </DarkTooltip>
      </Box>
    ) : (
      <span className="defaultTitle1">{e}</span>
    );
  }

  return (
    <section>
      <Stack direction="column" spacing={2} mt={2} mb={6}>
        <span className={`${styles.tab_title} ${styles.bold}`}>My Memorial Quilt Squares</span>

        <Stack spacing={2}>
          <span className="defaultText colorGrey">
            Manage the quilt squares that you have created here. You can
          </span>
          <ul>
            <li className={styles.list_item}>
              <span className={`${styles.list_dot}`}></span>Change how your quilt square is displayed (private/public),
            </li>
            <li className={styles.list_item}>
              <span className={`${styles.list_dot}`}></span>Edit, publish, or delete your quilt square,
            </li>
            <li className={styles.list_item}>
              <span className={`${styles.list_dot}`}></span>Invite or remove contributors,
            </li>
            <li className={styles.list_item}>
              <span className={`${styles.list_dot}`}></span>View your quilt square to read posted tributes and condolences,
            </li>
            <li className={styles.list_item}>
              <span className={`${styles.list_dot}`}></span>Share your quilt square on social media or send it to others by email.
            </li>
          </ul>
        </Stack>
      </Stack>
      {quiltsList && quiltsList.length > 0 ? (
        <TableContainer sx={{ maxWidth: '100%', overflowY: 'hidden' }}>
          <Table
            size="small"
            sx={{ overflow: 'auto', tableLayout: 'fixed', minWidth: '550px' }}
            className="customTable"
          >
            <TableHead>
              <TableRow>
                {matches_1400
                  ? myTableHeaderFields.map((e, idx) => (
                      <TableCell
                        key={idx}
                        align="left"
                        sx={{ width: sizeColumnMyMemorials[idx], border: 'none'}}
                      >
                          <span className="defaultTitle1">{e}</span>
                      </TableCell>
                    ))
                  : matches_900
                    ? myTableHeaderFieldsTablet.map((e, idx) => (
                        <TableCell
                          key={idx}
                          align="left"
                          sx={{ width: sizeColumnMyMemorialsTablet[idx], border: 'none' }}
                        >
                          <span className="defaultTitle1">{e}</span>
                        </TableCell>
                      ))
                    : myTableHeaderFieldsMobile.map((e, idx) => (
                        <TableCell
                          key={idx}
                          align="left"
                          sx={{ width: sizeColumnMyMemorialsMobile[idx], border: 'none' }}
                        >
                          <span className="defaultTitle1">{e}</span>
                        </TableCell>
                      ))}
              </TableRow>
            </TableHead>

            <TableBody>
              {quiltsList.map((quilt, index) => (
                <QuiltsTableRow
                  key={quilt.id}
                  quilt={quilt}
                  userEmail={user.email}
                  publicUnpublic={publicUnpublic}
                  publishUnpublish={publishUnpublish}
                  shareQuilt={shareQuilt}
                  removeAccess={removeAccess}
                  deleteQuilt={deleteUserQuilt}
                  rowStyle={styles.tableRowBorderBottom}
                />
              ))}
            </TableBody>
          </Table>
        </TableContainer>
      ) : (
        <Box className="defaultText">You have not created a quilt yet. <Link className="link" to="/create?step=1">Create your first quilt.</Link></Box>
      )}

      <Stack direction="column" spacing={2} mt={10} mb={6}>
        <span className="defaultTitle1">Other quilt squares I can edit</span>

        <span className="defaultText colorGrey">
          If you have been invited to contribute to other memorial quilt squares, you can access them here. Note that any changes you make will be saved as the most recent version.
        </span>
      </Stack>

      {editableList && editableList.length > 0 ? (
        <TableContainer sx={{ maxWidth: { xs: '100%', md: '100%' }, marginBottom: 4 }}>
          <Table size="small" sx={{ overflow: 'auto', tableLayout: 'fixed', minWidth: '550px' }}>
            <TableHead>
              <TableRow>
                {matches_900
                  ? tableHeaderFields.map((e, idx) => (
                      <TableCell
                        key={idx}
                        align="left"
                        sx={{ width: sizeColumnInviteMemorials[idx] || '', border: 'none' }}
                      >
                        {getStatusWithTooltipOrOtherItem(e)}
                      </TableCell>
                    ))
                  : tableHeaderFieldsMobile.map((e, idx) => (
                      <TableCell
                        key={idx}
                        align="left"
                        sx={{
                          width: sizeColumnInviteMemorialsMobile[idx] || '',
                          border: 'none',
                        }}
                      >
                        {getStatusWithTooltipOrOtherItem(e)}
                      </TableCell>
                    ))}
              </TableRow>
            </TableHead>

            <TableBody>
              {editableList.map((e) => (
                <TableRow key={e.id} className={styles.tableRowBorderBottom}>
                  <TableCell align="left" width={100} sx={{
                    overflow: "hidden"
                  }}>
                    <Box sx={{ width: 80, height: 80 }}>
                      <img
                        className={styles.imgTable}
                        src={e.finalImage ? e.finalImage.full : 'img/general/bg-2.jpg'}
                        alt={e.name}
                      />
                    </Box>
                    {!matches_900 && (
                      <Box className={styles.text}>
                        <span>{`${e?.firstName || ''} ${e?.lastName || ''}`} </span>
                      </Box>
                    )}
                  </TableCell>

                  {matches_900 && (
                    <TableCell align="left" className={styles.text}>
                      <span>{`${e?.firstName || ''} ${e?.lastName || ''}`} </span>
                    </TableCell>
                  )}

                  <TableCell align="left" sx={{
                    opacity: 0.7
                  }}>
                    <span className={styles.text} style={{ ...(e.isPublished ? { opacity: 0.5 } : {}) }}>
                      {' '}
                      Draft{' '}
                    </span>
                      <FormControlLabel
                        control={
                          <Switch disabled={true} checked={e.isPublished} onChange={() => publishUnpublish(e)} />
                        }
                        label={''}
                        className={styles.text}
                        sx={{
                          margin: 0,
                          marginTop: -1,
                        }}
                      />
                      <span className={styles.text} style={{ ...(!e.isPublished ? { opacity: 0.5 } : {}) }}>
                        {' '}
                        Published{' '}
                    </span>
                  </TableCell>

                  <TableCell style={{ padding: 5 }} align="left">
                    <ViewButton
                      viewButtonClick={() => {
                        onlyRedirect('/quilts/' + e.id + '/memorial');
                      }}
                      isEditableList={true}
                    />
                  </TableCell>

                  <TableCell style={{ padding: 5 }} align="left">
                    <EditButton editButtonClick={() => editButtonClick(e)} isEditableList={true} />
                  </TableCell>
                </TableRow>
              ))}
            </TableBody>
          </Table>
        </TableContainer>
      ) : (
        <Box className="defaultText" pb={{ xs: 4, md: 10 }}>
          You have not been invited to edit someone’s quilt square.
        </Box>
      )}
      <DefaultDialog
        open={errorPrivateDialog}
        setDialog={onSetErrorPrivatDialog}
        title={errorBuilderPrivateDialog.title}
        text={errorBuilderPrivateDialog.text}
        noCancelBtn
        confirmBtnText="OK"
        confirmAction={() => onSetErrorPrivatDialog(false)}
      />
    </section>
  );
};
