import { TDefaultListValue } from "../../../api/http-client/types";
import { v4 as uuidv4 } from 'uuid';

export const updateStateByValueInputList = (state: TDefaultListValue[], newValue: string, id: string) => {
    const newState = [...state]
  
    const currentIndex = newState.findIndex(item => item.id === id);
  
    newState[currentIndex] = {
      ...newState[currentIndex],
      value: newValue
    }
  
    return newState
  }
  
export const createStateInputList = (state: TDefaultListValue[]) => {
    return [
        ...state,
        {
        id: uuidv4(),
        value: ''
        }
    ]
}
  
export const removeStateInputListById = (state: TDefaultListValue[], index: number) => {
    const newState = [...state];

    newState.splice(index, 1);

    return newState;
}