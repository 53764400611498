import { FC } from 'react';
import { Outlet, useLocation } from 'react-router-dom';

import Footer from '../Footer';
import FullFooter from '../FullFooter';
import Header from '../Header';
import LineBlock from '../LineBlock';
import { Dialogs } from '../Dialogs';

interface LayoutProps {}

const Layout: FC<LayoutProps> = () => {
  const location = useLocation();

  return (
    <div className="App" style={{ height: '100vh', display: 'flex', flexDirection: 'column' }}>
      <Header />
      <LineBlock />
      <main style={{ flexGrow: 1 }}>
        <Outlet />
        <Dialogs />
      </main>
      <LineBlock />
      {location.pathname === '/' ? <FullFooter /> : <Footer />}
    </div>
  );
};

export default Layout;
