import { createSlice, PayloadAction } from '@reduxjs/toolkit';

export enum ProfileTab {
  One = 'one',
  Two = 'two',
  Three = 'three',
  Four = 'four',
}

const initialState = ProfileTab.One;

const profileTabSlice = createSlice({
  name: 'profileTab',
  initialState,
  reducers: {
    setTab: (state, action: PayloadAction<ProfileTab>) => {
      state = action.payload;
      return state;
    },
  },
});

export const { setTab } = profileTabSlice.actions;

export default profileTabSlice;
