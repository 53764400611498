import React from 'react';
import { Button } from '@mui/material';

import { useTranslates } from 'providers/translates';

interface ViewButtonProps {
  viewButtonClick: () => void;
  isEditableList?: boolean;
}
interface EditButtonProps {
  editButtonClick: () => void;
  isEditableList?: boolean;
}
interface DeleteButtonProps {
  deleteButtonClick: () => void;
}

interface InviteButtonProps {
  setCollapse: () => void;
}

export const ViewButton: React.FC<ViewButtonProps> = ({ viewButtonClick, isEditableList }) => {
  const { translates } = useTranslates();

  return (
    <Button
      size="medium"
      variant="text"
      onClick={viewButtonClick}
      sx={{
        color: '#cc7527',
        fontSize: isEditableList ? { xs: 18, md: 24 } : 24,
        textTransform: 'capitalize',
      }}
    >
      {translates?.userSettings?.tableRowBtnLabelEdit || 'View'}
    </Button>
  );
};

export const EditButton: React.FC<EditButtonProps> = ({ editButtonClick, isEditableList }) => {
  const { translates } = useTranslates();

  return (
    <Button
      size="medium"
      variant="text"
      onClick={editButtonClick}
      sx={{
        color: '#4c7e43',
        fontSize: isEditableList ? { xs: 18, md: 24 } : 24,
        textTransform: 'capitalize',
      }}
    >
      {translates?.userSettings?.tableRowBtnLabelEdit || 'Edit'}
    </Button>
  );
};

export const DeleteButton: React.FC<DeleteButtonProps> = ({ deleteButtonClick }) => {
  const { translates } = useTranslates();

  return (
    <Button
      size="medium"
      variant="text"
      onClick={deleteButtonClick}
      sx={{ 
        color: '#db4646', 
        fontSize: 24, 
        textTransform: 'capitalize'
      }}
    >
      {translates?.userSettings?.tableRowBtnLabelDelete || 'Delete'}
    </Button>
  );
};

export const InviteButton: React.FC<InviteButtonProps> = ({ setCollapse }) => {
  const { translates } = useTranslates();

  return (
    <Button
      size="medium"
      variant="text"
      onClick={setCollapse}
      sx={{ 
        color: '#333333', 
        fontSize: 24, 
        textTransform: 'capitalize'
      }}
    >
      {translates?.userSettings?.tableRowBtnLabelDelete || 'Invite'}
    </Button>
  );
};