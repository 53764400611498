import {httpClient} from '..';
import {CheckEmailResponse, TLoginRequest, TLoginResponse, TRegisterRequest, TRegisterResponse} from '../types';

export const login = async (body: TLoginRequest): Promise<TLoginResponse> => {
  const {data} = await httpClient.post('/accounts/login', {
    responseType: 'json',
    data: body,
  });

  return data;
};

export const register = async (body: TRegisterRequest): Promise<TRegisterResponse> => {
  const {data} = await httpClient.post('/accounts', {
    responseType: 'json',
    data: body,
  });
  return data
};


export const forgetPassword = async (email: string) => {
  await httpClient.post('/accounts/forgot-password', {
    data: JSON.stringify(email),
    headers: {'Content-Type': 'application/json'},
  });
}

export const resetPassword = async (userId: string | null, token: string | null, password: string) => {
  await httpClient.post('/accounts/reset-password', {
    responseType: 'json',
    data: {
      userId: userId,
      token: token,
      password: password
    }
  });
}

export const checkEmailExist = async (email: string): Promise<CheckEmailResponse> => {
  const data = await httpClient.get(`/accounts/${email}/is-free`);
  return data.data;
}
