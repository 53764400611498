import React, { FC, useEffect, useState } from 'react';
import { getLangFromLocalStorage, obtainTransl } from '../../providers/translates';
import MarkdownRenderer from '../../components/MarkdownRenderer';
import { strapiClient } from '../../api/http-client';
import './survey.scss';

const TakeOurSurvey: FC = () => {
  const [data, setData] = useState<React.ComponentState>();
  const [error, setError] = useState('');

  useEffect(() => {
    const readData = async () => {
      const response = await strapiClient
        .get('/take-our-survey-page', { params: { populate: 'deep,3' } })
        .catch((error) => {
          setError(error.message);
          console.log('error: ', error);
        });
      const responseData = response && response.data.data.attributes;
      responseData ? setData(obtainTransl(responseData)) : setData(null);
    };
    readData();
  }, []);

  const currentLangData = data ? data[getLangFromLocalStorage()] : null;

  return (
    <div className="center survey__content">
      {error ? (
        <h3 style={{ textAlign: 'center' }}>{error}</h3>
      ) : (
        currentLangData && (
          <>
            <h1 className="h1">{currentLangData.title}</h1>
            <div className="survey_info-block">
              <div className="survey__info-block__text">
                <MarkdownRenderer content={currentLangData.content} />
              </div>
            </div>
          </>
        )
      )}
    </div>
  );
};

export default TakeOurSurvey;
