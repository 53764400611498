import { Action, configureStore, ThunkAction } from '@reduxjs/toolkit';
// import { quiltsAPI } from '../services/quiltsService';
import reducers from './reducers';

export const store = configureStore({
  reducer: reducers,
  // middleware: (getDefaultMiddleWare) =>
  //   getDefaultMiddleWare({ serializableCheck: false }).concat(quiltsAPI.middleware),
});

export type AppDispatch = typeof store.dispatch;
export type RootState = ReturnType<typeof store.getState>;
export type AppThunk<ReturnType = void> = ThunkAction<
  ReturnType,
  RootState,
  unknown,
  Action<string>
>;
