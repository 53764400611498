import React from 'react';
import { Box, Button, Grid } from '@mui/material';
import { useParams } from 'react-router-dom';

import { TQuiltType } from '../../api/http-client/types';
import { useAuth } from '../../providers/auth-provider';
import { useTranslates } from '../../providers/translates';
import { fetchPublicQuiltWithMemorial } from '../../api/http-client/queries/quiltQueries';

import styles from './styles.module.scss';
import { SharedDialog } from '../../components/ShadedDialog/SaredDialog';
import { buttonStyle, getFormattedDate, notifyMessages } from '../../utilities';
import { TShareQuilt } from '../../api/http-client/profileTypes';
import { setOpen } from 'store/reducers/notifySlice';
import { StickyShareButtons } from 'sharethis-reactjs';
import {
  editableQuiltsList,
  getUserQuilts,
  inviteUserToQuilt,
} from '../../api/http-client/queries/profileQueries';
import { httpMessages } from '../../utilities/constants';
import {useAppDispatch, useRedirectAndScroll} from 'hooks';

const Memory: React.FC = () => {
  const { id } = useParams();
  const { user } = useAuth();
  const { translates } = useTranslates();
  const { onlyRedirect } = useRedirectAndScroll({})
  const dispatch = useAppDispatch();

  const [quilt, setQuilt] = React.useState<TQuiltType | null>(null);
  const [edit, setEdit] = React.useState(false);
  const [shareDialog, setShareDialog] = React.useState(false);
  const [fetchError, setFetchError] = React.useState<string | null>(null);

  React.useEffect(() => {
    if (!id) return;

    fetchPublicQuiltWithMemorial(id)
      .then((response) => {
        setQuilt(response.data.returned);
      })
      .catch(() =>
        setFetchError(
          translates?.httpMessages?.fetchPublicQuiltWithMemorialError ||
            httpMessages.fetchPublicQuiltWithMemorialError
        )
      );
    if (user) {
      getUserQuilts().then((q) => q.some((quilt) => quilt.id.toString() === id) && setEdit(true));
      editableQuiltsList().then(
        (q) => q.some((quilt) => quilt.id.toString() === id) && setEdit(true)
      );
    }
  }, [id, user]);

  const shareQuilt = (body: TShareQuilt) => {
    inviteUserToQuilt(body).finally(() => {
      dispatch(setOpen(translates?.notifyMessages?.accessAllowed || notifyMessages.accessAllowed));
    });
  };

  if (fetchError) return <div style={{ textAlign: 'center' }}>{fetchError}</div>;

  if (!quilt) return <div style={{ textAlign: 'center' }}>Loading...</div>;

  return (
    <div className={styles.quiltImg}>
      <Box sx={{ display: 'flex', flexDirection: 'column', alignItems: 'center' }}>
        <Box sx={{ maxWidth: '600px', p: 1 }}>
          {quilt?.finalImage?.full && <img src={quilt?.finalImage.full} alt="src" />}
        </Box>
        <Box sx={{ my: 3 }}>
          <p className={styles.quiltText}>
            {`${quilt?.name}`}
          </p>
          <p className={styles.quiltText}>
            {
              quilt?.dateOfPassing ? `${getFormattedDate(quilt?.dateOfBirth)} - ${getFormattedDate(quilt?.dateOfPassing)}` :
              `${getFormattedDate(quilt?.dateOfBirth)} - present`
            }
          </p>
        </Box>
        {edit && (
          <Grid container justifyContent="flex-end">
            <Button
              variant="outlined"
              sx={{ ...buttonStyle, mr: 3 }}
              onClick={() => setShareDialog(true)}
            >
              {translates?.memorialPage?.inviteBtnLabel || 'Invite'}
            </Button>
            <Button
              variant="outlined"
              sx={buttonStyle}
              onClick={() => {
                onlyRedirect('/update/' + id);
              }}
            >
              {translates?.memorialPage?.editBtnLabel || 'Edit'}
            </Button>
          </Grid>
        )}
      </Box>
      {id && !!user && (
        <SharedDialog
          id={id.toString()}
          userEmail={user.userName || ''}
          setShareDialog={setShareDialog}
          shareDialog={shareDialog}
          shareQuilt={shareQuilt}
        />
      )}

      <StickyShareButtons
        config={{
          alignment: 'right',
          color: 'social',
          enabled: true,
          font_size: 16,
          labels: 'cta',
          language: 'en',
          min_count: 0,
          networks: ['facebook', 'twitter', 'email'],
          padding: 12,
          radius: 4,
          show_total: false,
          show_mobile: true,
          show_toggle: true,
          size: 48,
          top: 320,
        }}
      />
    </div>
  );
};

export default Memory;
