import Baha from './baha.svg';
import Bell from './bell.svg';
import Crescent from './crescent.svg';
import Cross from './cross.svg';
import David from './david.svg';
import Dharma from './dharma.svg';
import Heart from './Heart.svg';
import IconCandle from './Icon-Candle.svg';
import IconEagleFeather from './Icon-Eagle-Feather-1.svg';
import IconInuilt from './Icon-Inuit.svg';
import IconLitOilLamp from './Icon-Lit-Oil-Lamp-1.svg';
import IconMetis from './Icon-Metis.svg';
import IconPoppy from './Icon-Poppy.svg';
import IconPraying from './Icon-Praying.svg';
import IconSmudgeBowl from './Icon-Smudge-Bowl-2.svg';
import IconThankYou from './Icon-Thank-You.svg';
import Jainism from './jainism.svg';
import Khanda from './khanda.svg';
import Lilly01 from './lilly-01.svg';
import Om from './om.svg';
import Rose from './rose.svg';
import Shinto from './shinto.svg';
import Tao from './tao.svg';
import Tulip from './tulip.svg';

interface IconObject {
  icon: string | null;
  title: string;
};

interface Icons {
  [key: string]: IconObject;
};

const IconSvg = {
  baha: { icon: Baha, title: 'Baha' },
  bell: { icon: Bell, title: 'Bell' },
  crescent: { icon: Crescent, title: 'Crescent' },
  cross: { icon: Cross, title: 'Cross' },
  david: { icon: David, title: 'David' },
  dharma: { icon: Dharma, title: 'Dharma' },
  heart: { icon: Heart, title: 'Heart' },
  candle: { icon: IconCandle, title: 'Icon Candle' },
  eagleFeather: { icon: IconEagleFeather, title: 'Icon Eagle Feather' },
  inuilt: { icon: IconInuilt, title: 'Icon Inuilt' },
  litOilLamp: { icon: IconLitOilLamp, title: 'Icon Lit Oil Lamp' },
  metis: { icon: IconMetis, title: 'Icon Metis' },
  poppy: { icon: IconPoppy, title: 'Icon Poppy' },
  praying: { icon: IconPraying, title: 'Icon Praying' },
  smudgeBowl: { icon: IconSmudgeBowl, title: 'Icon Smudge Bowl' },
  thankYou: { icon: IconThankYou, title: 'Icon Thank You' },
  jainism: { icon: Jainism, title: 'Jainism' },
  khanda: { icon: Khanda, title: 'Khanda' },
  lilly01: { icon: Lilly01, title: 'Icon Lilly-01' },
  om: { icon: Om, title: 'Om' },
  rose: { icon: Rose, title: 'Rose' },
  shinto: { icon: Shinto, title: ' Icon Shinto' },
  tao: { icon: Tao, title: 'Icon Tao' },
  tulip: { icon: Tulip, title: 'Tulip' }
} as Icons;

const sortedIcons = [
    IconSvg.candle,
    IconSvg.heart,
    IconSvg.thankYou,
    IconSvg.poppy,
    IconSvg.praying,
    IconSvg.eagleFeather,
    IconSvg.litOilLamp,
    IconSvg.smudgeBowl,
    IconSvg.metis,
    IconSvg.inuilt,
    IconSvg.rose,
    IconSvg.tulip,
    IconSvg.david,
    IconSvg.dharma,
    IconSvg.cross,
    IconSvg.om,
    IconSvg.khanda,
    IconSvg.crescent,
    IconSvg.baha,
    IconSvg.jainism,
    IconSvg.bell,
    IconSvg.shinto,    
    IconSvg.tao,
    IconSvg.lilly01
];

export { IconSvg, sortedIcons };
