import React, { useEffect, useMemo, useState } from 'react';
import {
  Box,
  CircularProgress,
  Collapse,
  IconButton,
  Table,
  TableBody,
  TableCell,
  TableContainer,
  TableHead,
  TablePagination,
  TableRow,
  TableSortLabel,
} from '@mui/material';
import { visuallyHidden } from '@mui/utils';
import moment from 'moment';
import ArrowDropUpIcon from '@mui/icons-material/ArrowDropUp';
import ArrowDropDownIcon from '@mui/icons-material/ArrowDropDown';

import { colors } from 'utilities';
import { AdminUserQuilt, OwnerObject } from 'api/http-client/types';
import { QuiltTableHeader } from './const';
import { LockAndDeleteBox } from '../LockAndDeleteBox';
import { QuiltDetails } from './QuiltDetails';

interface QuiltTableProps {
  visibleRows: AdminUserQuilt[];
  page: number;
  count: number;
  rowsPerPage: number;
  loading: boolean;
  userId?: string;
  handleChangePage: (event: unknown, newPage: number) => void;
  handleChangeRowsPerPage: (event: React.ChangeEvent<HTMLInputElement>) => void;
  onQuiltRefetch: ({ sortBy, sortField }: { sortBy: Order; sortField: string }) => void;
}

export interface IQuiltTableContent {
  quiltId: number;
  quiltFullName: string;
  dateCreated: string;
  datePublished: string;
  public: string;
  tributesEnabled: string;
  finalImage: string | undefined;
  contributors: AdminUserQuilt['contributors'];
  locked: boolean;
  deleted: boolean;
  owner?: OwnerObject;
  reasonForDelete?: string;
  reasonForLock?: string;
}

export type Order = 'asc' | 'desc';

function descendingComparator<T>(a: T, b: T, orderBy: keyof T) {
  if (b[orderBy] < a[orderBy]) {
    return -1;
  }
  if (b[orderBy] > a[orderBy]) {
    return 1;
  }
  return 0;
}

function getComparator<Key extends keyof any>(
  order: Order,
  orderBy: Key
): (a: { [key in Key]: number | string }, b: { [key in Key]: number | string }) => number {
  return order === 'desc'
    ? (a, b) => descendingComparator(a, b, orderBy)
    : (a, b) => -descendingComparator(a, b, orderBy);
}

function stableSort<T>(
  array: readonly T[] | IQuiltTableContent[],
  comparator: (a: T, b: T) => number
) {
  const stabilizedThis = array.map((el, index) => [el, index] as [T, number]);
  stabilizedThis.sort((a, b) => {
    const order = comparator(a[0], b[0]);
    if (order !== 0) {
      return order;
    }
    return a[1] - b[1];
  });

  return stabilizedThis.map((el) => el[0]) as unknown as IQuiltTableContent[];
}

export const QuiltTable: React.FC<QuiltTableProps> = ({
  visibleRows,
  page,
  count,
  rowsPerPage,
  loading,
  userId,
  handleChangePage,
  handleChangeRowsPerPage,
  onQuiltRefetch,
}) => {
  const [showQuiltDetails, setShowQuiltDetails] = useState({
    status: false,
    quiltId: 0,
  });

  const [order, setOrder] = useState<Order>('desc');
  const [orderBy, setOrderBy] = useState<string>('dateCreated');

  const quiltTableContent: IQuiltTableContent[] = useMemo(
    () =>
      visibleRows.map((row) => ({
        quiltId: row.id,
        quiltFullName: `${row.firstName} ${row.lastName}`,
        dateCreated: moment(row.dateCreated || '').format('MM/DD/YYYY'),
        datePublished: row.datePublished
          ? moment(row.datePublished).format('MM/DD/YYYY')
          : '',
        public: row.public ? 'Public' : 'Private',
        tributesEnabled: row.tributesEnabled ? 'Yes' : 'No',
        finalImage: row.finalImage?.full,
        contributors: row.contributors,
        locked: row.locked,
        deleted: row.deleted,
        owner: row.owner,
        reasonForDelete: row?.reasonForDelete || '',
        reasonForLock: row?.reasonForLock || ''
      })),
    [visibleRows]
  );

  useEffect(() => {
    setShowQuiltDetails({ status: false, quiltId: 0 });
  }, [loading]);

  const createSortHandler = (property: string) => () => {
    const isAsc = orderBy === property && order === 'asc';
    onQuiltRefetch({ sortBy: isAsc ? 'desc' : 'asc', sortField: property });
    setOrder(isAsc ? 'desc' : 'asc');
    setOrderBy(property);
  };

  const sortedRows = useMemo(() => {
    return stableSort(quiltTableContent, getComparator(order, orderBy));
  }, [quiltTableContent, order, orderBy]);

  return (
    <section>
      <TableContainer sx={{ mt: 6 }}>
        <Table sx={{ minWidth: 650 }} aria-label="quilts table">
          <TableHead>
            <TableRow>
              {QuiltTableHeader.map((header) => (
                <TableCell
                  key={header.id}
                  align={header.align}
                  sx={{
                    width: { xs: 'auto', lg: header.width },
                    fontSize: { xs: 16, sm: 20, lg: 24 },
                    color: colors.dark_blue,
                    fontWeight: 700,
                  }}
                >
                  {header.id === 'button' ? (
                    header.label
                  ) : (
                    <TableSortLabel
                      active={orderBy === header.id}
                      direction={orderBy === header.id ? order : 'asc'}
                      onClick={createSortHandler(header.id)}
                    >
                      {header.label}
                      {orderBy === header.id ? (
                        <Box component="span" sx={visuallyHidden}>
                          {order === 'desc' ? 'sorted descending' : 'sorted ascending'}
                        </Box>
                      ) : null}
                    </TableSortLabel>
                  )}
                </TableCell>
              ))}
            </TableRow>
          </TableHead>
          <TableBody>
            {loading ? (
              <TableRow>
                <TableCell align="center" colSpan={6} sx={{ fontSize: { xs: 16, sm: 18, lg: 20 } }}>
                  <CircularProgress color="inherit" />
                </TableCell>
              </TableRow>
            ) : sortedRows.length ? (
              sortedRows.map((row) => (
                <React.Fragment key={row.quiltId}>
                  <TableRow>
                    <TableCell
                      sx={{
                        fontSize: { xs: 16, sm: 18, lg: 20 },
                        fontWeight: 700,
                        minWidth: { xs: '200px', lg: '350px' },
                      }}
                    >
                      {row.quiltFullName}
                    </TableCell>
                    <TableCell align="center" sx={{ fontSize: { xs: 16, sm: 18, lg: 20 } }}>
                      {row.dateCreated}
                    </TableCell>
                    <TableCell align="center" sx={{ fontSize: { xs: 16, sm: 18, lg: 20 } }}>
                      {row.datePublished ? row.datePublished : 'Not Published'}
                    </TableCell>
                    <TableCell align="center" sx={{ fontSize: { xs: 16, sm: 18, lg: 20 } }}>
                      {row.public}
                    </TableCell>
                    <TableCell align="center" sx={{ fontSize: { xs: 16, sm: 18, lg: 20 } }}>
                      {row.tributesEnabled}
                    </TableCell>
                    <TableCell align="center" sx={{ fontSize: { xs: 16, sm: 18, lg: 20 } }}>
                      <IconButton
                        aria-label="expand row"
                        size="small"
                        onClick={() => {
                          setShowQuiltDetails({
                            status:
                              showQuiltDetails.quiltId === row.quiltId
                                ? !showQuiltDetails.status
                                : true,
                            quiltId: row.quiltId,
                          });
                        }}
                      >
                        {showQuiltDetails.status && showQuiltDetails.quiltId === row.quiltId ? (
                          <ArrowDropUpIcon fontSize="large" />
                        ) : (
                          <ArrowDropDownIcon fontSize="large" />
                        )}
                      </IconButton>
                    </TableCell>
                  </TableRow>
                  <TableRow
                    style={{
                      visibility:
                        showQuiltDetails.status && showQuiltDetails.quiltId === row.quiltId
                          ? 'visible'
                          : 'collapse',
                    }}
                  >
                    <TableCell style={{ padding: 0, width: '100%' }} colSpan={6}>
                      <Collapse in={showQuiltDetails.status} timeout={1000}>
                        <QuiltDetails row={row} userId={userId} />
                        <LockAndDeleteBox
                          id={showQuiltDetails.quiltId}
                          row={row}
                          isUser={false}
                          userId={userId}
                        />
                      </Collapse>
                    </TableCell>
                  </TableRow>
                </React.Fragment>
              ))
            ) : (
              <TableRow>
                <TableCell align="center" colSpan={6} sx={{ fontSize: { xs: 16, sm: 18, lg: 20 } }}>
                  No quilts to display
                </TableCell>
              </TableRow>
            )}
          </TableBody>
        </Table>
      </TableContainer>
      {count > 5 && (
        <TablePagination
          rowsPerPageOptions={[5, 10, 15]}
          component="div"
          count={count}
          rowsPerPage={rowsPerPage}
          page={page}
          onPageChange={handleChangePage}
          onRowsPerPageChange={handleChangeRowsPerPage}
        />
      )}
    </section>
  );
};
