import React from 'react';

import { initialState } from '../../api/auth';
import { IAuthContext } from './types';

const stub = () => {
  throw new Error('<AuthProvider> not found');
};

export const AuthContext = React.createContext<IAuthContext>({
  login: stub,
  logout: stub,
  register: stub,
  setUser: stub,
  loading: false,
  ...initialState,
});
