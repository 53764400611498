import { BlockModel, EditorDataModel, ImageModel, ReverseBlockValueModel, TSideBlock, TSideChangePositions } from "./model";

export const getValueFromBlock = (valueId: string, valueSide: TSideChangePositions, valueBlocks: BlockModel[]) : ReverseBlockValueModel | null => {
    const index = valueBlocks.findIndex(block => block.id === valueId);

    // check type text
    const editorData = valueBlocks?.[index]?.editorData;

    if (typeof editorData === "object" && valueSide !== "full" && editorData[valueSide] ) {
        const value = editorData[valueSide]

        if (!value) return null

        return {
            type: "editorData",
            value,
            index
        }
    } else if (typeof editorData === "string") {
      return {
        type: "editorData",
        value: {
          value: editorData,
          isRender: true
        },
        index
      }
    }

    const image = valueBlocks?.[index]?.image ;

    if (image && !("file" in image) && valueSide !== "full" && image[valueSide] ) {
        const value = image[valueSide]

        if (!value) return null

        return {
            type: "image",
            value,
            index
        }
    } else if (image && ("file" in image)) {
      return {
        type: "image",
        value: {
          image,
          isRender: true
        },
        index
      }
    }

    return null;
}

export const getNewBlocksWithoutBlockBySide = (valueBlocks: BlockModel[], block: ReverseBlockValueModel, side: TSideBlock) => {
    const currentValueByType = valueBlocks[block.index][block.type];
    const updateCurrentValueByType : EditorDataModel | ImageModel = {};

    if (currentValueByType && typeof currentValueByType === "object" && !("file" in currentValueByType)) {
      for (const key in currentValueByType) {
        const currentKey = key as TSideBlock

        if (side !== currentKey) updateCurrentValueByType[currentKey] = currentValueByType[currentKey];
      }
    }

    return {
      ...valueBlocks[block.index],
      [block.type]: {
        ...(updateCurrentValueByType)
      }
    }
}
