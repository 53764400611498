import React from 'react';
import { useParams, useLocation } from 'react-router-dom';
import { Box } from '@mui/material';

import { AddUsers, InvitedUsers } from '../components';
import { useAppSelector, useRedirectAndScroll } from '../../../hooks';

import styles from './styles.module.scss';

interface CreateMemorialQuiltStepTwoProps {}

export const CreateMemorialQuiltStepTwo: React.FC<CreateMemorialQuiltStepTwoProps> = () => {
  const { id } = useParams();
  const location = useLocation();
  const editableQuilt = useAppSelector((state) => state.quilt.editableQuilt);
  const { redirectAndScroll } = useRedirectAndScroll({});

  const backAction = () => {
    redirectAndScroll(`${location.pathname}?step=1`);
  };

  const continueAction = () => {
    redirectAndScroll(`${location.pathname}?step=3`);
  };

  return (
    <div className="center">
      <div className={styles.title}>
        <span>
          Memorial quilt square for{' '}
        </span>
        <span>
          {editableQuilt?.firstName || ''} {editableQuilt?.lastName || ''}
        </span>
      </div>

      <div className={styles.inviteOther}>
        <h2 className="defaultTitle">Invite others to contribute</h2>
      </div>

      {id ? (
        <Box mt={6}>
          <AddUsers pageName={`${editableQuilt?.firstName || ''} ${editableQuilt?.lastName || ''}`} id={id}></AddUsers>
        </Box>
      ) : <></>}

      <div className={`delimitierGrey ${styles['separator-gap']}`}></div>

      <div className={styles.actions}>
        <button className="customButton" onClick={backAction}>
          BACK
        </button>
        <button className="customButton" onClick={continueAction}>
          CONTINUE
        </button>
      </div>
    </div>
  );
};
