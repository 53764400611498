interface IQuiltTableHeader {
  id: string;
  label: string;
  width: string;
  align: 'inherit' | 'left' | 'center' | 'right' | 'justify';
}

export const QuiltTableHeader: IQuiltTableHeader[] = [
  {
    id: 'quiltFullName',
    label: 'Name',
    width: '350px',
    align: 'left',
  },
  {
    id: 'dateCreated',
    label: 'Date created',
    width: 'auto',
    align: 'center',
  },
  {
    id: 'datePublished',
    label: 'Date published',
    width: 'auto',
    align: 'center',
  },
  {
    id: 'public',
    label: 'Public/Private',
    width: 'auto',
    align: 'center',
  },
  {
    id: 'tributesEnabled',
    label: 'Tributes enabled',
    width: 'auto',
    align: 'center',
  },
  {
    id: 'button',
    label: '',
    width: 'auto',
    align: 'center',
  },
];

export enum QuiltSortField {
  FIRST_NAME = 'firstName',
  DATE_CREATED = 'dateCreated',
  DATE_PUBLISHED = 'datePublished',
}
