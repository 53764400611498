import {
  Box,
  Button,
  Dialog,
  FormControl,
  FormControlLabel,
  FormLabel,
  Radio,
  RadioGroup,
  TextField,
  Typography,
} from '@mui/material';
import React from 'react';
import {buttonStyle} from '../../utilities';
import {TShareQuilt} from '../../api/http-client/profileTypes';
import {useTranslates} from '../../providers/translates';

export const SharedDialog: React.FC<{
  userEmail: string;
  id: string;
  setShareDialog: (sharedDialog: boolean) => void;
  shareQuilt: (body: TShareQuilt) => void;
  shareDialog: boolean
}> = ({userEmail, id, shareQuilt, setShareDialog, shareDialog}) => {
  const {translates} = useTranslates();
  const [shared, setShared] = React.useState({email: '', message: '', edit: false});

  return (
    <>
      <Dialog
        maxWidth="sm"
        fullWidth
        open={shareDialog}
        onClose={() => setShareDialog(!shareDialog)}
      >
        <Box sx={{py: 3, px: 2}}>
          <Typography variant="h5">
            {translates?.userSettings?.tableRowSharedOptionDialogTitle ||
            'Invite to MemorialQuilt creation'}
          </Typography>
          <TextField
            sx={{mt: 3}}
            value={shared.email}
            onChange={(e) => setShared({...shared, email: e.target.value})}
            fullWidth
            label={
              translates?.userSettings?.tableRowSharedOptionDialogInviteUserEmailFieldLabel ||
              'Email to'
            }
            helperText={
              translates?.userSettings?.tableRowSharedOptionDialogInviteUserEmailFieldHelperText ||
              'User should be registered'
            }
          />
          <TextField
            sx={{mt: 2}}
            fullWidth
            label={
              translates?.userSettings?.tableRowSharedOptionDialogUserEmailFieldLabel || 'Your email'
            }
            InputProps={{readOnly: true}}
            value={userEmail}
          />
          <TextField
            sx={{mt: 2}}
            fullWidth
            value={shared.message}
            onChange={(e) => setShared({...shared, message: e.target.value})}
            label={
              translates?.userSettings?.tableRowSharedOptionDialogMessageFieldLabel || 'Your message'
            }
            multiline
            rows={2}
          />

          <Box sx={{display: 'flex', justifyContent: 'space-between', mt: 2}}>
            <FormControl>
              <FormLabel>
                {translates?.userSettings?.tableRowSharedOptionDialogRecipientOptionsTitle ||
                'The recipient can:'}
              </FormLabel>
              <RadioGroup row>
                <FormControlLabel
                  checked={!shared.edit}
                  onChange={() => setShared({...shared, edit: !shared.edit})}
                  control={<Radio/>}
                  label={
                    translates?.userSettings?.tableRowSharedOptionDialogRecipientOption1Label ||
                    'Add images only'
                  }
                />
                <FormControlLabel
                  checked={shared.edit}
                  onChange={() => setShared({...shared, edit: !shared.edit})}
                  control={<Radio/>}
                  label={
                    translates?.userSettings?.tableRowSharedOptionDialogRecipientOption2Label ||
                    'Add images & edit quilt'
                  }
                />
              </RadioGroup>
            </FormControl>

            <Button
              variant="contained"
              sx={{...buttonStyle, height: 1, mt: 2}}
              onClick={() => {
                // shareQuilt({...shared, id});
                setShareDialog(false);
              }}
            >
              {translates?.userSettings?.tableRowSharedOptionDialogSendBtnLabel || 'Send Invite'}
            </Button>
          </Box>
        </Box>
      </Dialog>
    </>
  );
};
