import {TShareQuilt, TProfile, TRemoveAccess, TUserQuilt, TUpdateShareQuilt} from '../profileTypes';
import { httpClient } from '../index';

export const getProfile = async () => {
  const { data } = await httpClient.get(`/profiles`);
  return data.returned as TProfile;
};

export const getUserQuilts = async (): Promise<TUserQuilt[]> => {
  const { data } = await httpClient.get(`/users/quilts/list`);
  return data.returned as TUserQuilt[];
};

export const editableQuiltsList = async (): Promise<TUserQuilt[]> => {
  const { data } = await httpClient.get(`/users/quilts/contribute`);
  return data.returned;
};

export const updateProfile = async (body: TProfile) => {
  await httpClient.put(`/profiles`, {
    responseType: 'json',
    data: body,
  });
};

export const inviteUserToQuilt = async ({ id, email, message, access }: TShareQuilt) => {
  await httpClient.post(`/quilts/${id}/contributors`, {
    responseType: 'json',
    data: {
      email: email,
      message: message,
      access: access,
    },
  });
};

export const updatedInvitedUserToQuilt = async ({ id, email, access }: TUpdateShareQuilt) => {
  await httpClient.put(`/quilts/${id}/contributors`, {
    responseType: 'json',
    data: {
      [email ? 'email' : '']: email,
      [access ? 'access' : '']: access,
    },
  });
};

export const removeUserAccess = async ({ id, email, message }: TRemoveAccess) => {
  await httpClient.delete(`/quilts/${id}/contributors`, {
    responseType: 'json',
    data: {
      email: email,
      message: message,
    },
  });
};

export const deleteQuilt = async (id: string) => {
  await httpClient.delete(`/quilts/${id}`, {
    responseType: 'json',
    params: {
      reason: 'Test quilt',
    },
  });
};

export const changeUserPassword = async (password: string) => {
  await httpClient.put(`/accounts/password`, {
    responseType: 'json',
    data: {
      newPassword: password,
    },
  });
};
