import { createSlice } from '@reduxjs/toolkit';
import { TGetProfileResponse, TUserData } from '../../http-client/types';

import { TAuthStorageState } from '../types';

const LOCAL_STORAGE_KEY = 'auth-user-session';

const getSessionFromLocalStorage = () => {
  try {
    const storageItem = localStorage.getItem(LOCAL_STORAGE_KEY);
    if (storageItem) {
      return JSON.parse(storageItem);
    }
    return storageItem;
  } catch (error) {
    return null;
  }
};

const setSessionToLocalStorage = (session: (TUserData & TGetProfileResponse) | null) => {
  if (session === null) {
    localStorage.removeItem(LOCAL_STORAGE_KEY);
  } else {
    localStorage.setItem(LOCAL_STORAGE_KEY, JSON.stringify(session));
  }
};

export const initialState: TAuthStorageState = {
  user: null,
  isAuthorized: false,
};

const initState = () => {
  const session = getSessionFromLocalStorage();
  return {
    ...initState,
    user: session,
    isAuthorized: !!session,
  };
};

export const authSlice = createSlice({
  name: 'auth',
  initialState: initState,
  reducers: {
    setUser: (state, action) => {
      state.user = action.payload;
      state.isAuthorized = true;
      setSessionToLocalStorage(action.payload);
    },
    removeUser: (state) => {
      state.user = null;
      state.isAuthorized = false;
      setSessionToLocalStorage(null);
    },
  },
});

export const reducer = authSlice.reducer;
export const actions = authSlice.actions;
