import React from 'react';
import { TTranslatesContext } from './types';

const stub = () => {
  throw new Error('<TranslatesProvider> not found');
};

export const TranslatesContext = React.createContext<TTranslatesContext>({
  translates: {},
  setTranslates: stub,
  selectedLang: 'en-CA',
  setSelectedLang: stub,
});
