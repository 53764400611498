import { configureStore } from '@reduxjs/toolkit';

import { reducer } from './slice';

export const authStore = configureStore({
  reducer,
});

export { actions, initialState } from './slice';
export type TAuthState = ReturnType<typeof authStore.getState>;
