import { createSlice, PayloadAction } from '@reduxjs/toolkit';
import { AdminUserQuilt } from 'api/http-client/types';

const initialState: AdminUserQuilt[] = [];

const quiltAdminSlice = createSlice({
  name: 'adminQuilt',
  initialState,
  reducers: {
    setQuilts: (state, action: PayloadAction<AdminUserQuilt[]>) => {
      return action.payload;
    },
    updateQuilt: (state, action: PayloadAction<Partial<AdminUserQuilt>>) => {
      const index = state.findIndex((quilt) => quilt.id === action.payload.id);
      if (index !== -1) {
        state[index] = { ...state[index], ...action.payload };
      }
    },
    removeQuilts: () => {
      return initialState;
    },
  },
});

export const { setQuilts, updateQuilt, removeQuilts } = quiltAdminSlice.actions;

export default quiltAdminSlice;
