const accessImageDialog = {
  title: 'Access your photos',
  text: 'We need your permission to access your photos.',
};

const successBuilderDialog = {
  title: 'Success!',
  text: 'You have completed your memorial quilt square!',
};

const selectingWordStyle = "'color: #333333; text-transform: uppercase; font-weight: 700'";

const errorBuilderPublishDialog = {
  title: 'Error!',
  text: `
		<div style="margin-bottom: 6px">
		 Your memorial is still in draft form!
		</div>
		Select your display settings and click <span style=${selectingWordStyle}>PUBLISH</span> to complete your memorial. If you are not ready to <span style=${selectingWordStyle}>PUBLISH</span>, you can view your memorial by clicking <span style=${selectingWordStyle}>PREVIEW</span>.
	`,
};

const errorBuilderPrivateDialogTitle = "'margin-bottom: 6px; font-weight: 700; color: #333333'";

const errorBuilderPrivateDialog = {
  title: 'Warning!',
  text: `
			<div style=${errorBuilderPrivateDialogTitle}>
				Your quilt is currently set to private.
			</div>
			Since your quilt is currently set to private, it will not be visible on the Canadian Healing Quilt on the home page or serachable by name.
		`,
};

export {
  accessImageDialog,
  successBuilderDialog,
  errorBuilderPublishDialog,
  errorBuilderPrivateDialog,
};
