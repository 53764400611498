import { useEffect } from 'react';

import { fetchTranslates } from './api/http-client/queries/strapi';
import AppRouter from './components/AppRouter';
import { useTranslates } from './providers/translates';

import './App.css';

function App() {
  const { setTranslates } = useTranslates();

  useEffect(() => {
    fetchTranslates().then((res) => setTranslates(res));
  }, []);

  return <AppRouter />;
}

export default App;
