import React from 'react';
import { TextField } from '@mui/material';
import SearchIcon from '@mui/icons-material/Search';

import { DarkTooltip } from 'components/Tooltips/CustomTooltips';
import { colors } from 'utilities';

import styles from './adminDashboard.module.scss';
import {UserSearchModel} from "./index";
import { handleKeyDown } from './helpers/handle-key-down';

interface SearchBoxProps {
  value: UserSearchModel;
  loading: boolean;
  onChangeClick: (value: string, key: keyof UserSearchModel) => void;
  searchClick: () => void;
  refreshClick: () => void;
}

export const SearchBoxUsers: React.FC<SearchBoxProps> = ({
  value,
  loading,
  onChangeClick,
  searchClick,
  refreshClick,
}) => {
  return (
    <section>
        <div className={styles.searchBlockUsers}>
          <div className="inputWithTitle">
            <span>Email</span>

            <TextField
              className="customInput"
              size="small"
              value={value.email}
              onChange={({ target }) => onChangeClick(target.value, 'email')}
              type="search"
              placeholder="Email"
              onKeyDown={(event) => handleKeyDown(event, searchClick)}
              InputProps={{
                endAdornment: (
                  <DarkTooltip title="Search user">
                    <SearchIcon onClick={searchClick} sx={{ cursor: 'pointer', fontSize: { xs: 30, sm: 40 }, color: colors.grey, ml: 2 }} />
                  </DarkTooltip>
                ),
              }}
            />
          </div>

          <div className="inputWithTitle">
            <span>Last Name</span>

            <TextField
              className="customInput"
              size="small"
              value={value.lastName}
              onChange={({ target }) => onChangeClick(target.value, 'lastName')}
              type="search"
              placeholder="Last Name"
              onKeyDown={(event) => handleKeyDown(event, searchClick)}
              InputProps={{
                endAdornment: (
                  <DarkTooltip title="Search user">
                    <SearchIcon onClick={searchClick} sx={{ cursor: 'pointer', fontSize: { xs: 30, sm: 40 }, color: colors.grey, ml: 2 }} />
                  </DarkTooltip>
                ),
              }}
            />
          </div>

          <div className="inputWithTitle">
            <span>First Name</span>

            <TextField
              className="customInput"
              size="small"
              value={value.firstName}
              onChange={({ target }) => onChangeClick(target.value, 'firstName')}
              type="search"
              placeholder="First Name"
              onKeyDown={(event) => handleKeyDown(event, searchClick)}
              InputProps={{
                endAdornment: (
                  <DarkTooltip title="Search user">
                    <SearchIcon onClick={searchClick} sx={{ cursor: 'pointer', fontSize: { xs: 30, sm: 40 }, color: colors.grey, ml: 2 }} />
                  </DarkTooltip>
                ),
              }}
            />
          </div>
        </div>
      <div className={styles.searchButton_wrapper}>
        <button className={`customButton ${styles.adminBtn}`} onClick={refreshClick}>
          Refresh
        </button>
        <button
          disabled={loading}
          className={`customButton ${styles.adminBtn}`}
          onClick={searchClick}
        >
          Search
        </button>
      </div>
    </section>
  );
};
