import React, { FC } from 'react';

import { CarouselItem } from '../CarouselItemType/CarouselItem';
import { QuiltsResponseMega } from '../../../../api/http-client/types';
import { CarouselItemType } from "../CarouselItemType/CarouselItemType"
import { CarouselItemType1 } from "../CarouselItemType/CarouselItemType1"
import { DataSettingModel } from "../../../../hooks/useCarouselHome";

interface RowDeskPropsModel {
    indexOfRenderRow: number,
    items: QuiltsResponseMega[]
    settingData: DataSettingModel
}

export const RowTablet: FC<RowDeskPropsModel> = ({
   indexOfRenderRow,
   items,
}) => {
    return (
        <>
            {
                indexOfRenderRow % 2 === 0 ? (
                    <CarouselItem>
                        {
                            items.map((item, index) => {
                                return (
                                    <React.Fragment key={index}>
                                        <CarouselItemType isStub></CarouselItemType>
                                        {
                                            index % 2 === 0 ? (
                                                <CarouselItemType item={item}></CarouselItemType>
                                            ) : (
                                                <CarouselItemType1 item={item}></CarouselItemType1>
                                            )
                                        }
                                    </React.Fragment>
                                )
                            })
                        }
                    </CarouselItem>
                ) : (
                    <CarouselItem>
                        {
                            items.map((item, index) => {
                                return (
                                    <React.Fragment key={index}>
                                        {
                                            index % 2 !== 0 ? (
                                                <CarouselItemType item={item}></CarouselItemType>
                                            ) : (
                                                <CarouselItemType1 item={item}></CarouselItemType1>
                                            )
                                        }
                                        <CarouselItemType isStub></CarouselItemType>
                                    </React.Fragment>
                                )
                            })
                        }
                    </CarouselItem>
                )
            }
        </>
    )
}
