import { FC, useEffect, useState } from 'react';
import { CreateImagesBlock } from '../../components/CreateMemorialQuiltComponents/components/CreateImagesBlock';
import stylesDialogsBlocks from '../../components/CreateMemorialQuiltComponents/DialogBlocks/styles.module.scss';

import styles from './styles.module.scss';
import { useParams } from 'react-router-dom';

import { editUnpublishedQuilt } from '../../api/http-client/queries/quiltQueries';
import { TQuiltCreateUpdate } from '../../api/http-client/types';
import { useAppDispatch, useAppSelector } from '../../hooks';
import { setEditableQuiltState } from '../../store/reducers/quiltSlice';
import { getName } from '../../utilities';
import { Loader } from '../../components/Loader/Loader';

interface AddImagesOnlyProps {}

const AddImagesOnly: FC<AddImagesOnlyProps> = () => {  
  const { id } = useParams();

  const [loading, setLoading] = useState(false);

  const dispatch = useAppDispatch()
  const editableQuiltStore = useAppSelector(state => state.quilt.editableQuilt);
  const name = getName({
    firstName: editableQuiltStore?.firstName,
    lastName: editableQuiltStore?.lastName
  })

  useEffect(() => {
    if (!id) return;

    setLoading(true)

    editUnpublishedQuilt(id).then((data: TQuiltCreateUpdate) => {
      dispatch(setEditableQuiltState(data))      
    }).finally(() => setLoading(false))
  }, [])

  return (
    <div
      className={`${stylesDialogsBlocks.wrap} ${styles.wrap}`}
      style={{
        background: 'center / cover no-repeat url("/img/general/background-preview.png")',
      }}
    >
      <div className={`${stylesDialogsBlocks.headerImg} ${styles.headerImg}`}>
        <img
          className={`${stylesDialogsBlocks.imgLine} ${styles.imgLine}`}
          src="/img/general/top-line-header-preview.png"
          alt="line"
        />
      </div>

      <div className="center">
      {/* TODO [Healing Quilt/Saluting Service to honor] */}
        <div className={styles.title}>
          <span className="defaultTitle">Add Images to { name }</span>
        </div>
        <CreateImagesBlock setImageBlock={() => ''} isAddImagesOnly></CreateImagesBlock>
      </div>

      <Loader loading={loading} />
    </div>
  );
};

export default AddImagesOnly;
