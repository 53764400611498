import { FC } from 'react';
import { QuiltsResponseMega } from '../../../../api/http-client/types';
import WithTooltipWrap from '../WithTooltipWrap';
import styles from "../../styles.module.scss"
import {useRedirectAndScroll} from "../../../../hooks";

interface CarouselItemType1Model {
  item: QuiltsResponseMega
}

export const CarouselItemType1: FC<CarouselItemType1Model> = ({item}) => {
  const { onlyRedirect } = useRedirectAndScroll({})
  const isTouch = 'ontouchstart' in window || navigator.maxTouchPoints > 0

  const goToMemorial = () => {
    if (!isTouch || !item) return;

    onlyRedirect(`quilts/${item.id}/memorial`);
  }

  return (
    <>
    { item && <WithTooltipWrap
        children={
          <div onClick={goToMemorial} className={styles.carousel__content_item1}>
            <div className={styles.carousel__content_item1Border}></div>
            <img className={styles.carousel__content_item1Img} src={item?.finalImage?.full || '/img/general/dummy-person.jpg'} alt={item?.name || 'person-icon'}></img>
          </div>
        }
          id={item?.id}
          url={item?.finalImage?.full || '/img/general/dummy-person.jpg'}
          name={item?.name || `${item?.firstName} ${item?.lastName}`}
          dateOfBirth={item?.dateOfBirth?.year || 1970}
          dateOfPassing={item?.dateOfPassing?.year || 1970}
          customWidth={300}
          setError={() => ''}
      ></WithTooltipWrap> }
    </>
  )
}
