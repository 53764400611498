import { editUnpublishedQuilt, getQuiltsMega } from 'api/http-client/queries/quiltQueries';
import { QuiltsResponseMega } from 'api/http-client/types';
import { TDirection } from 'components/HomeCarousel/model';
import { Dispatch, MutableRefObject, SetStateAction, useEffect, useState } from 'react';
import { useMediaQuery } from '@mui/material';
import { useSearchParams } from 'react-router-dom';

interface UseCarouselHomeReturnModel {
  toggleZIndex?: (control: boolean) => void;
  getMovePositionBySide?: () => string;
  handleTransitionEnd?: () => void;
  data: QuiltsResponseMega[][] | undefined;
  setData: Dispatch<SetStateAction<QuiltsResponseMega[][] | undefined>>;
  settingData: DataSettingModel;
  loading: boolean;
}

interface UseCarouselHomeParamsModel {
  containerMoveRef: MutableRefObject<HTMLDivElement | null>;
  containerMainRef: MutableRefObject<HTMLDivElement | null>;
  moveSide: TDirection;
  setMoveSide: Dispatch<SetStateAction<TDirection>>;
}

export interface DataSettingModel {
  limitMega: number;
  groupLimit: number;
  rowLimit: number;
}

export const useCarouselHome = ({
  containerMoveRef,
  containerMainRef,
  moveSide,
  setMoveSide,
}: UseCarouselHomeParamsModel): UseCarouselHomeReturnModel => {
  const [searchParams, setSearchParams] = useSearchParams();

  const matches1240 = useMediaQuery('(max-width: 1240px)');

  const [data, setData] = useState<QuiltsResponseMega[][]>();
  const [loading, setLoading] = useState<boolean>(false);
  const [settingData, setSettingData] = useState<DataSettingModel>({
    limitMega: 150,
    groupLimit: 7,
    rowLimit: matches1240 ? 6 : 5,
  });
  useEffect(() => {
    // set group limit
    if (window.screen.width <= 2000 && window.screen.width >= 1240) {
      setSettingData((data) => {
        return {
          ...data,
          groupLimit: 4,
        };
      });
    }

    // window.addEventListener('resize', handleResizeUpdateSettingData);

    setLoading(true);

    getQuiltsMega(settingData.limitMega)
      .then((data) => {
        if (!data) return;

        // visible functionality (view quilt on home page)
        const visibleId = searchParams.get('visible');

        if (visibleId) {
          editUnpublishedQuilt(visibleId).then((quilt) => {
            const dataWithoutThisQuilt = data.filter((item) => item.id !== +visibleId);

            setData(getPreparingData([quilt, ...dataWithoutThisQuilt]));
          });
        } else {
          setData(getPreparingData(data));
        }
      })
      .finally(() => setLoading(false));

    // return () => {
    //   window.removeEventListener('resize', handleResizeUpdateSettingData);
    // };
  }, []);

  const getPreparingData = (data: QuiltsResponseMega[]) => {
    let group: QuiltsResponseMega[] = [];

    if (data.length < settingData.groupLimit) {
      return [data];
    }

    return data?.reduce((total, item) => {
      group.push(item);

      if (group.length === settingData.groupLimit) {
        total.push(group);
        group = [];
      }

      return total;
    }, [] as QuiltsResponseMega[][]);
  };

  // const handleResizeUpdateSettingData = () => {
  //   setSettingData((state) => {
  //     return {
  //       ...state,
  //       groupLimit: 7,
  //     };
  //   });
  // };

  return {
    data,
    loading,
    setData,
    settingData,
  };
};
