import React from 'react';
import { toast, ToastContainer } from 'react-toastify';
import 'react-toastify/dist/ReactToastify.css';

type TVariants = 'success' | 'error' | 'info';

const notify = (notifyMessage: string, access: TVariants) => {
  const options = {
    position: toast.POSITION.TOP_CENTER,
    autoClose: 4000,
    closeOnClick: true,
    pauseOnHover: true,
    draggable: true,
    bodyClassName: 'toast-body',
  };
  const variants = {
    success: () => toast.success(notifyMessage, options),
    error: () => toast.error(notifyMessage, options),
    info: () => toast.info(notifyMessage, options),
  };
  variants[access]();
};

const NotifyToastContext = React.createContext<{
  notify: (notifyMessage: string, access: TVariants) => void;
}>({
  notify: notify,
});
export const useNotify = () => React.useContext(NotifyToastContext);

export const NotifyContext: React.FC = () => {
  return (
    <>
      <ToastContainer
        position="top-center"
        autoClose={4000}
        closeOnClick
        rtl={false}
        pauseOnFocusLoss
        draggable
        pauseOnHover
        icon={false}
        hideProgressBar
        // toastStyle={{ backgroundColor: '#24476a' }}
      />
    </>
  );
};
