import React, { FC, useEffect, useState } from 'react';
import { getLangFromLocalStorage, obtainTransl } from '../../providers/translates';
import MarkdownRenderer from '../../components/MarkdownRenderer';
import FaqItem from './FaqItem';
import { strapiClient } from '../../api/http-client';
import './faq.scss';

const Faq: FC = () => {
  const [faqs, setFaqs] = useState<React.ComponentState>();
  const [error, setError] = useState('');

  useEffect(() => {
    const readFaqs = async () => {
      const response = await strapiClient
        .get('/faq-page', { params: { populate: 'deep,3' } })
        .catch((error) => {
          setError(error.message);
          console.log('error: ', error);
        });
      const responseData = response && response.data.data.attributes;
      responseData ? setFaqs(obtainTransl(responseData)) : setFaqs(null);
    };
    readFaqs();
  }, []);

  const currentLangFaqs = faqs ? faqs[getLangFromLocalStorage()] : null;

  return (
    <div className="center faq__content">
      {error ? (
        <h3 style={{ textAlign: 'center' }}>{error}</h3>
      ) : (
        currentLangFaqs && (
          <>
            <h1 className="h1">{currentLangFaqs.title}</h1>
            <div className="faq__info-block">
              <div className="faq__info-block__text">
                <MarkdownRenderer content={currentLangFaqs.content} />
              </div>
              {currentLangFaqs.videoUrl && (
                <div className="faq__info-block__video">
                  <iframe
                    title="preview-video"
                    src={currentLangFaqs.videoUrl}
                    width="100%"
                    height="100%"
                    frameBorder="0"
                    allow="autoplay; fullscreen; picture-in-picture"
                    allowFullScreen
                  ></iframe>
                </div>
              )}
            </div>

            <div>
              <div className="faq">
                <div className="faq__items">
                  {currentLangFaqs.questions.map((el: React.ComponentState) => (
                    <FaqItem key={el.id} title={el.title} content={el.content} />
                  ))}
                </div>
              </div>
            </div>
          </>
        )
      )}
    </div>
  );
};

export default Faq;
