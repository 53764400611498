import React from 'react';
import { TextField } from '@mui/material';
import SearchIcon from '@mui/icons-material/Search';

import { DarkTooltip } from 'components/Tooltips/CustomTooltips';
import { colors } from 'utilities';

import styles from './adminDashboard.module.scss';
import { handleKeyDown } from './helpers/handle-key-down';

interface SearchBoxProps {
  value: string;
  placeholder: string;
  loading: boolean;
  onChangeClick: (value: string) => void;
  searchClick: () => void;
  refreshClick: () => void;
}

export const SearchBox: React.FC<SearchBoxProps> = ({
  value,
  placeholder,
  loading,
  onChangeClick,
  searchClick,
  refreshClick,
}) => {
  return (
    <section>
      <div className="inputWithTitle">
        <TextField
          className="customInput"
          size="small"
          value={value}
          onChange={({ target }) => onChangeClick(target.value)}
          type="search"
          placeholder={placeholder}
          onKeyDown={(event) => handleKeyDown(event, searchClick)}
          InputProps={{
            endAdornment: (
              <DarkTooltip title="Search user">
                <SearchIcon onClick={searchClick} sx={{ cursor: 'pointer', fontSize: { xs: 30, sm: 40 }, color: colors.grey, ml: 2 }} />
              </DarkTooltip>
            ),
          }}
        />
      </div>
      <div className={styles.searchButton_wrapper}>
        <button className={`customButton ${styles.adminBtn}`} onClick={refreshClick}>
          Refresh
        </button>
        <button
          disabled={loading}
          className={`customButton ${styles.adminBtn}`}
          onClick={searchClick}
        >
          Search
        </button>
      </div>
    </section>
  );
};
