import { FC, useEffect, useState } from 'react';
import { Button } from '@mui/material';
import Slider from 'react-slick';

import { subscribeToNews } from '../../api/http-client/queries/newsletters';
import { emailRegex, notifyMessages } from '../../utilities/constants';
import { useTranslates } from '../../providers/translates';
import { ArrowPropsModel } from '../HomeCarousel/components/Arrows';
import { setOpen } from 'store/reducers/notifySlice';
import LineBlock from '../LineBlock';
import { FOOTER_LOGOS } from './logos';
import Footer from '../Footer';
import { useAppDispatch } from 'hooks';

import './footer.scss';

const CustomLeftArrow:  FC<ArrowPropsModel> = ({ onClick, ...rest }) => {
  return (
    <div className="angle-left slick-arrow" onClick={onClick}>
      <i className="fa fa-chevron-left"></i>
    </div>
  );
};

const CustomRightArrow:  FC<ArrowPropsModel> = ({ onClick, ...rest }) => {
  return (
    <div className="angle-right slick-arrow" onClick={onClick}>
      <i className="fa fa-chevron-right"></i>
    </div>
  );
};

const slidesToShow = (screenWidth: number) => {
  if (screenWidth <= 420) return 1;
  if (screenWidth <= 700) return 2;
  if (screenWidth <= 900) return 3;
  return 3;
};

const FullFooter: FC = () => {
  const { translates } = useTranslates();
  const dispatch = useAppDispatch();

  const [width, setWidth] = useState<number>(window.innerWidth);
  const [email, setEmail] = useState('');

  function handleWindowSizeChange() {
    setWidth(window.innerWidth);
  }

  useEffect(() => {
    window.addEventListener('resize', handleWindowSizeChange);
    return () => {
      window.removeEventListener('resize', handleWindowSizeChange);
    };
  }, []);

  useEffect(() => {
    // eslint-disable-next-line react-hooks/exhaustive-deps
    settings = {
      ...settings,
      slidesToShow: slidesToShow(width),
    };
  }, [width]);

  let settings = {
    arrows: true,
    dots: false,
    infinite: true,
    speed: 500,
    slidesToShow: slidesToShow(document.documentElement.clientWidth),
    slidesToScroll: 1,
    nextArrow: <CustomRightArrow />,
    prevArrow: <CustomLeftArrow />,
  };

  return (
    <>
      <section style={{ backgroundColor: '#fff' }}>
        <div className="partners center">
          <div
            style={{
              color: '#4fa6c6',
              fontStyle: 'italic',
              fontSize: '31px',
              fontWeight: 600,
              padding: '28px',
            }}
          >
            {translates?.footer?.partnersLabel || 'Partners'}
          </div>
          <div style={{ position: 'relative', padding: '28px 0', margin: '0 30px' }}>
            <Slider {...settings}>
              {FOOTER_LOGOS.map((footerImg, i) => (
                <div key={i} style={{ display: 'flex', alignItems: 'center' }}>
                  <div className="carousel_item">
                    <img
                      style={{
                        height: i === 0 ? '124px' : '65px',
                        maxWidth: '100%',
                        objectFit: 'contain',
                      }}
                      src={footerImg}
                      alt="logo"
                    />
                  </div>
                </div>
              ))}
            </Slider>
          </div>
        </div>
        <LineBlock />
        <div className="landing-footer">
          <div className="center">
            <div className="contact">
              <div className="footer-col">
                <div className="title">{translates?.footer?.subscribe || 'Subscribe'}</div>
                <div className="desc">
                  {translates?.footer?.subscribeText ||
                    `Our free monthly newsletter offers the latest news, tools, and resources for
                palliative care, advanced illness, and grief.`}
                </div>
                <div className="subscribe-email">
                  <input
                    onChange={({ target }) => setEmail(target.value)}
                    type="email"
                    placeholder={translates?.footer?.emailPlaceHolder || 'Email Address'}
                    value={email}
                  />
                  <Button
                    sx={{ lineHeight: 'initial' }}
                    className="submit-btn colored-btn featured-btn"
                    onClick={() => {
                      if (email.length) {
                        if (!emailRegex.test(email)) {
                          dispatch(
                            setOpen(
                              translates?.notifyMessages?.subscriptionError ||
                                notifyMessages.invalidEmail
                            )
                          );
                          return;
                        }
                        subscribeToNews(email)
                          .then(() => {
                            dispatch(
                              setOpen(
                                translates?.notifyMessages?.subscriptionSuccess ||
                                  notifyMessages.subscriptionSuccess
                              )
                            );
                            setEmail('');
                          })
                          .catch((err) => {
                            dispatch(
                              setOpen(
                                (
                                  err?.errors || [
                                    translates?.notifyMessages?.subscriptionError ||
                                      notifyMessages.subscriptionError,
                                  ]
                                ).join(' ')
                              )
                            );
                          });
                      }
                    }}
                  >
                    {translates?.footer?.signUp || 'Subscribe'}
                    <i className="fa fa-chevron-right"></i>
                  </Button>
                </div>
              </div>
              <div className="footer-col">
                <div className="title">{translates?.footer?.donate || 'Donate'}</div>
                <div className="desc">
                  {translates?.footer?.donateText ||
                    `Please help us continue our work. Support our registered charity, the International
                Centre for Dignity and Palliative Care, Inc.`}
                </div>
                <Button
                  sx={{ lineHeight: 'initial', p: '12px 25px !important' }}
                  className="donate colored-btn featured-btn"
                  href="https://www.canadahelps.org/en/charities/the-international-centre-for-dignity-and-palliative-care-inc/"
                  target="__blank"
                >
                  {translates?.footer?.donateToday || 'Donate today'}
                  <i className="fa fa-chevron-right"></i>
                </Button>
              </div>
              <div className="footer-col">
                <div className="title">{translates?.footer?.follow || 'Follow'}</div>
                <div className="desc">
                  {translates?.footer?.followText ||
                    `Socialize with #VirtualHospice for more news, stories and resources from us and our
                partners across Canada and around the world.`}
                </div>
                <div className="icon-links">
                  <a
                    className="link-item"
                    href="https://www.facebook.com/pages/Canadian-Virtual-Hospice/119170451459541?ref=ts"
                    target="__blank"
                  >
                    <i className="fa fa-facebook-f"></i>
                  </a>
                  <a
                    className="link-item"
                    href="https://twitter.com/VirtualHospice"
                    target="__blank"
                  >
                    <i className="fa fa-twitter"></i>
                  </a>
                  <a
                    className="link-item"
                    href="https://www.instagram.com/canadianvirtualhospice/"
                    target="__blank"
                  >
                    <i className="fa fa-instagram"></i>
                  </a>
                  <a
                    className="link-item"
                    href="https://www.youtube.com/user/cvhcvcsp?ob=video-mustangbase"
                    target="__blank"
                  >
                    <i className="fa fa-youtube"></i>
                  </a>
                  <a
                    className="link-item"
                    href="https://www.linkedin.com/company/canadian-virtual-hospice"
                    target="__blank"
                  >
                    <i className="fa fa-linkedin"></i>
                  </a>
                </div>
              </div>
            </div>
          </div>
        </div>
      </section>
      <Footer />
    </>
  );
};

export default FullFooter;
