import axios, { AxiosInstance, Method, AxiosRequestConfig, AxiosResponse } from 'axios';

import Debug from '../../utilities/debug';

const debug = Debug.extend('connector');

class HttpClient {
  static authorizationToken: string | null = null;

  config: AxiosRequestConfig;
  extendedErrorHandler?: (config: AxiosRequestConfig) => any;
  axios: null | AxiosInstance = null;

  constructor(
    config: AxiosRequestConfig = {},
    extendedErrorHandler?: (config: AxiosRequestConfig) => any
  ) {
    this.config = config;
    this.extendedErrorHandler = extendedErrorHandler;
    this.axios = axios.create(config);
  }

  /**
   * Set auth token to the HttpClient class
   */
  static authorize(token: string) {
    this.authorizationToken = token;
  }

  /**
   * Remove auth token from the HttpClient class
   */
  static unauthorize() {
    this.authorizationToken = null;
  }

  /**
   * Main request action
   * @param {string} path - url path
   * @param {Method} method - http method
   * @param {AxiosRequestConfig} [config] - additional axios config
   */
  private async query(path: string, method: Method, config: AxiosRequestConfig = {}) {
    try {
      return await this.axios!.request({
        ...config,
        url: path,
        method: method,
        headers: {
          ...(this.config.headers || {}),
          ...(config.headers || {}),
          ...(HttpClient.authorizationToken
            ? { Authorization: `Bearer ${HttpClient.authorizationToken!}` }
            : {}),
        },
      });
    } catch (error) {
      if (error.response) return this.errorHandler(error.response, [path, method, config]);
      throw error;
    }
  }

  async get(path: string, config?: AxiosRequestConfig) {
    return this.query(path, 'GET', config);
  }

  async post(path: string, config?: AxiosRequestConfig) {
    return this.query(path, 'POST', config);
  }

  async put(path: string, config?: AxiosRequestConfig) {
    return this.query(path, 'PUT', config);
  }

  async patch(path: string, config?: AxiosRequestConfig) {
    return this.query(path, 'PATCH', config);
  }

  async delete(path: string, config?: AxiosRequestConfig) {
    return this.query(path, 'DELETE', config);
  }

  /**
   * Default error handler. You can transform error here.
   */
  async errorHandler(response: AxiosResponse, originPayload: [string, Method, AxiosRequestConfig]) {
    if (!response) {
      throw new Error('No response');
    }

    switch (response.status) {
      case 401:
        debug(`Error Handler: trying to handle request error; code: 401, source: '%O'`, response);
        // ...
        throw response.data.error;
      default:
        break;
    }

    if (this.extendedErrorHandler) {
      debug(`Error Handler: trying to handle with Extended Error Handler`);
      return this.extendedErrorHandler(response);
    }

    throw response.data;
  }
}

export default HttpClient;
