import { Box, Fade, Paper, Popper } from "@mui/material"
import { LightTooltip } from "components/Tooltips/CustomTooltips"
import ReCAPTCHA from "react-google-recaptcha"
import styles from '../styles.module.scss'
import {TooltipProps} from "@mui/material/Tooltip/Tooltip";

interface ICaptchaTooltip {
    setCaptcha: (captcha: string | null) => void
    children: any
    disableHoverListener?: boolean
    tooltipProps?: Omit<TooltipProps, 'title' | 'children'>
}

// interface ICaptchaPopper {
//     setCaptcha: React.Dispatch<React.SetStateAction<string | null>>
//     showPoper: boolean
//     anchorEl?: HTMLButtonElement | null
//
// }

export const CaptchaTooltip = ({ disableHoverListener, setCaptcha, children, tooltipProps }: ICaptchaTooltip) => {
    return <LightTooltip
        {...tooltipProps}
        disableHoverListener={disableHoverListener}
        disableTouchListener={disableHoverListener}
        leaveTouchDelay={4000}
        title={
            <>
                {
                    process.env.REACT_APP_RECAPTCHA_SECRET_KEY &&
                    <Box className={styles.reCaptcha}>
                        <ReCAPTCHA
                            onChange={(value) => { setCaptcha(value) }}
                            sitekey={process.env.REACT_APP_RECAPTCHA_SECRET_KEY}
                        />
                    </Box>
                }
            </>
        }
        maxWidth={'320px'}
        PopperProps={{
            disablePortal: true,
            keepMounted: false
        }}
    >
        {children}
    </LightTooltip>
}

// export const CaptchaPopper = ({ showPoper, anchorEl, setCaptcha }: ICaptchaPopper) => {
//     return <Popper open={showPoper}
//         sx={{ zIndex: 2000 }}
//         placement='top'
//         anchorEl={anchorEl}
//         keepMounted={true}
//         transition>
//         {({ TransitionProps }) => (
//             <Fade {...TransitionProps} >
//                 <Paper>
//                     {process.env.REACT_APP_RECAPTCHA_SECRET_KEY &&
//                         <Box className={styles.reCaptcha}  >
//                             <ReCAPTCHA
//                                 onChange={(value) => { setCaptcha(value) }}
//                                 sitekey={process.env.REACT_APP_RECAPTCHA_SECRET_KEY}
//                             />
//                         </Box>
//                     }
//                 </Paper>
//             </Fade>
//         )}
//     </Popper>
// }
