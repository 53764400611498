import { useState, FC } from 'react';
import { Autocomplete, Box, Grid, TextField } from '@mui/material';
import { Controller, useForm } from 'react-hook-form';

import { TProfile } from 'api/http-client/profileTypes';
import { emailRegex, postalCodeRegex } from 'utilities';
import { useTranslates } from 'providers/translates';

import styles from "./styles.module.scss";

const gridSize = {
  xs: 12,
  md: 6,
  xl: 4,
};

const provinces = ['AB', 'BC', 'MB', 'NB', 'NL', 'NT', 'NS', 'NU', 'ON', 'PE', 'QC', 'SK', 'YT', '']

export const UserDetails: FC<{
  user: TProfile;
  saveUserData: (data: TProfile) => void;
}> = ({ user, saveUserData }) => {
  const { translates } = useTranslates();
  const [province, setProvince] = useState<string>(user.province)

  const { control, handleSubmit } = useForm<TProfile>({
    mode: 'onSubmit',
    reValidateMode: 'onSubmit',
    defaultValues: {
      ...user
    }
  });

  const onSubmit = (data: TProfile) => {
    saveUserData({ ...data, province })
  }

  return (
    <Box>
      <form onSubmit={handleSubmit(onSubmit)}>
        <Grid container spacing={4}>
          <Controller
            name="firstName"
            control={control}
            rules={{ required: true }}
            render={({ field: { onChange, value }, fieldState: { error } }) => (
              <Grid item {...gridSize}>
                <div className="inputWithTitle">
                  <span>{ translates?.userSettings?.firstNameFieldLabel || 'First Name' }<span className="requiredSymbol">*</span></span>
                  <TextField
                    className="customInput"
                    error={!!error}
                    fullWidth
                    value={value}
                    onChange={onChange}
                    helperText={
                      !!error
                        ? `${translates?.userSettings?.firstNameHelperText || 'Required field'}`
                        : ''
                    }
                    FormHelperTextProps={{ style: { fontSize: 16 } }}
                  />
                </div>
              </Grid>
            )}
          />
          <Controller
            name="lastName"
            control={control}
            rules={{ required: true }}
            render={({ field: { onChange, value }, fieldState: { error } }) => (
              <Grid item {...gridSize}>
                <div className="inputWithTitle">
                  <span>{ translates?.userSettings?.lastNameFieldLabel || 'Last Name' }<span className="requiredSymbol">*</span></span>
                  <TextField
                    className="customInput"
                    error={!!error}
                    fullWidth
                    value={value}
                    onChange={onChange}
                    helperText={
                      !!error
                        ? `${translates?.userSettings?.firstNameHelperText || 'Required field'}`
                        : ''
                    }
                    FormHelperTextProps={{ style: { fontSize: 16 } }}
                  />
                </div>
              </Grid>
            )}
          />
          <Controller
            name="email"
            control={control}
            rules={{ required: { value: true, message: 'Email is required' }, pattern: emailRegex }}
            render={({ field: { onChange, value }, fieldState: { error } }) => (
              <Grid item {...gridSize}>
                <div className="inputWithTitle">
                  <span>{ translates?.userSettings?.emailFieldLabel || 'Email Address' }<span className="requiredSymbol">*</span></span>
                  <TextField
                    className="customInput"
                    fullWidth
                    error={!!error}
                    value={value}
                    onChange={onChange}
                    placeholder="example@gmail.com"
                    helperText={
                      !!error
                        ? `${
                          translates?.userSettings?.emailHelperText || error.message ||
                          'Required field. For example: example@gmail.com'
                        }`
                        : ''
                    }
                    FormHelperTextProps={{ style: { fontSize: 16 } }}
                  />
                </div>
              </Grid>
            )}
          />
          <Controller
            name="postalCode"
            control={control}
            rules={{ pattern: postalCodeRegex }}
            render={({ field: { onChange, value }, fieldState: { error } }) => (
              <Grid sx={{marginLeft:'auto'}} item {...gridSize}>
                <div className="inputWithTitle">
                  <span>{ translates?.userSettings?.postalCodeFieldLabel || 'Postal Code' }<span className="requiredSymbol">*</span></span>
                  <TextField
                    className="customInput"
                    fullWidth
                    error={!!error}
                    value={value}
                    placeholder="K1A 0B1"
                    helperText={
                      !!error
                        ? `${
                          translates?.userSettings?.postalCodeHelperText || 'For example: K1A 0B1'
                        }`
                        : ''
                    }
                    onChange={onChange}
                    FormHelperTextProps={{ style: { fontSize: 16 } }}
                  />
                </div>
              </Grid>
            )}
          />
          <Grid sx={{marginLeft:{md:'auto',xl:0}}} item {...gridSize}>
            <div className="inputWithTitle">
              <span>{ translates?.userSettings?.provinceFieldLabel || 'Province' }</span>
              <Autocomplete
                fullWidth
                value={province}
                onChange={(event, newValue) => {
                  setProvince(newValue || '');
                }}
                options={provinces}
                renderInput={(params) => <TextField className="customInput" {...params} />}
              />
            </div>
          </Grid>
        </Grid>

        <Box sx={{ display: 'flex', justifyContent: 'flex-end' }}>
          <button className={`customButton ${styles.buttonChangeProfile}`} type="submit">{translates?.userSettings?.saveUserFormBtnLabel || 'Save Changes'}</button>
        </Box>
      </form>
    </Box>
  )
}
