import axios, { AxiosInstance, Method, AxiosRequestConfig, AxiosResponse } from 'axios';

import Debug from '../../utilities/debug';

const debug = Debug.extend('connector');

class StrapiClient {
  config: AxiosRequestConfig;
  axios: null | AxiosInstance = null;

  constructor(config: AxiosRequestConfig = {}) {
    this.config = config;
    this.axios = axios.create(config);
  }

  private async query(path: string, method: Method, config: AxiosRequestConfig = {}): Promise<any> {
    try {
      return await this.axios!.request({
        ...config,
        url: path,
        method: method,
        headers: {
          ...(this.config.headers || {}),
          ...(config.headers || {}),
        },
      });
    } catch (error) {
      if (error.response) return this.errorHandler(error.response, [path, method, config]);
      throw error;
    }
  }

  async get(path: string, config?: AxiosRequestConfig) {
    return this.query(path, 'GET', config);
  }

  async post(path: string, config?: AxiosRequestConfig) {
    return this.query(path, 'POST', config);
  }

  async put(path: string, config?: AxiosRequestConfig) {
    return this.query(path, 'PUT', config);
  }

  async patch(path: string, config?: AxiosRequestConfig) {
    return this.query(path, 'PATCH', config);
  }

  async delete(path: string, config?: AxiosRequestConfig) {
    return this.query(path, 'DELETE', config);
  }

  async errorHandler(response: AxiosResponse, originPayload: [string, Method, AxiosRequestConfig]) {
    if (!response) {
      throw new Error('No response');
    }

    switch (response.status) {
      case 401:
        debug(`Error Handler: trying to handle request error; code: 401, source: '%O'`, response);
        throw response.data.error;
      default:
        break;
    }

    throw response?.data?.error;
  }
}

export default StrapiClient;
