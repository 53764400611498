import { FC } from 'react';
import { Accordion, AccordionSummary, AccordionDetails, Typography } from '@mui/material/';
import ExpandMoreIcon from '@mui/icons-material/ExpandMore';
import './faq.scss';

const FaqItem: FC<{ title: string; content: string }> = ({ title, content }) => {
  return (
    <Accordion className="faq__item">
      <AccordionSummary
        expandIcon={<ExpandMoreIcon className="faq__item-arrow" fontSize="large" />}
        aria-controls="panel1a-content"
        id="panel1a-header"
      >
        <Typography
          sx={{ fontSize: '20px', fontFamily: 'Open Sans, sans-serif', fontWeight: 'bold' }}
          variant="h3"
          className="faq__item-title"
        >
          {title}
        </Typography>
      </AccordionSummary>
      <AccordionDetails>
        <div className="faq__item-body">
          <p>{content}</p>
        </div>
      </AccordionDetails>
    </Accordion>
  );
};

export default FaqItem;
