import React, { useEffect } from 'react';
import { Dialog, Stack } from '@mui/material';

import { useAppDispatch, useAppSelector } from 'hooks';
import { setClose } from 'store/reducers/notifySlice';

import { colors } from 'utilities';

export const NotifyNew: React.FC = () => {
  const dispatch = useAppDispatch();
  const notifyStore = useAppSelector((state) => state.notify);

  useEffect(() => {
    let timer: NodeJS.Timeout;
    if (notifyStore.isOpen) {
      timer = setTimeout(() => {
        dispatch(setClose());
      }, 10000);
      return () => clearTimeout(timer);
    }
    return () => clearTimeout(timer);
  }, [dispatch, notifyStore.isOpen]);

  return (
    <Dialog onClose={() => dispatch(setClose())} open={notifyStore.isOpen}>
      <Stack
        justifyContent="center"
        alignItems="center"
        p={{ xs: 2, md: 4 }}
        spacing={4}
        minWidth={{ xs: '250px', md: '400px' }}
      >
        <span style={{ textAlign: 'center', lineHeight: '32px', color: colors.dark_grey }}>
          {notifyStore.message}
        </span>
        <button className="customButton" onClick={() => dispatch(setClose())}>
          OK
        </button>
      </Stack>
    </Dialog>
  );
};
