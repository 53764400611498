import React, {useEffect, useState} from 'react';
import { TextField } from '@mui/material';

import {
  deleteQuilt,
  deleteUser,
  lockQuilt,
  lockUser,
  unlockQuilt,
  unlockUser,
} from 'api/http-client/queries/administration';
import { Loader } from 'components/Loader/Loader';
import { useAppDispatch } from 'hooks';
import { updateQuilt } from 'store/reducers/adminQuiltSlice';
import { updateUser, updateUserQuilt } from 'store/reducers/adminUserSlice';
import { setOpen } from 'store/reducers/notifySlice';

import styles from './adminDashboard.module.scss';
import {IUserTableContent} from "./UserTable";
import {IQuiltTableContent} from "./QuiltTable";

interface LockAndDeleteBoxProps {
  id: string | number;
  isUser: boolean;
  userId?: string;
  row: IUserTableContent | IQuiltTableContent;
}

export const LockAndDeleteBox: React.FC<LockAndDeleteBoxProps> = ({
  id,
  isUser,
  userId,
  row
}) => {
  const [reasons, setReasons] = useState({
    forLock: '',
    forDelete: '',
  });
  const [loading, setLoading] = useState(false);

  const dispatch = useAppDispatch();

  useEffect(() => {
    setReasons({
      forLock: row?.reasonForLock || '',
      forDelete: row?.reasonForDelete || '',
    })
  }, [])

  const lockClick = () => {
    if (row?.locked) {
      setLoading(true);
      isUser
        ? unlockUser(id.toString())
            .then(() => {
              dispatch(setOpen('User unrow.locked successfully'));
              dispatch(updateUser({ userId: id.toString(), locked: false }));
            })
            .catch((err) => {
              dispatch(setOpen(err.message || err.errors?.[0]));
            })
            .finally(() => {
              setLoading(false);
            })
        : unlockQuilt(id.toString())
            .then(() => {
              dispatch(setOpen('Quilt unrow.locked successfully'));
              userId
                ? dispatch(
                    updateUserQuilt({ userId: userId, quilt: { id: Number(id), locked: false } })
                  )
                : dispatch(updateQuilt({ id: Number(id), locked: false }));
            })
            .catch((err) => {
              dispatch(setOpen(err.message || err.errors?.[0]));
            })
            .finally(() => {
              setLoading(false);
            });
    } else {
      if (reasons.forLock === '') {
        dispatch(setOpen('Please enter a reason for lock'));
        return;
      }
      setLoading(true);
      isUser
        ? lockUser({ userId: id.toString(), reason: reasons.forLock })
            .then(() => {
              dispatch(setOpen('User row.locked successfully'));
              setReasons({ ...reasons, forLock: '' });
              dispatch(updateUser({ userId: id.toString(), locked: true }));
            })
            .catch((err) => {
              if (`${err.errors[0]}`.toLowerCase() === "invalid email.") return;

              dispatch(setOpen(err.message || err.errors?.[0]));
            })
            .finally(() => {
              setLoading(false);
            })
        : lockQuilt({ quiltId: id.toString(), reason: reasons.forLock })
            .then(() => {
              dispatch(setOpen('Quilt row.locked successfully'));
              setReasons({ ...reasons, forLock: '' });
              userId
                ? dispatch(
                    updateUserQuilt({ userId: userId, quilt: { id: Number(id), locked: true } })
                  )
                : dispatch(updateQuilt({ id: Number(id), locked: true }));
            })
            .catch((err) => {
              if (`${err.errors[0]}`.toLowerCase() === "invalid email.") return;

              dispatch(setOpen(err.message || err.errors?.[0]));
            })
            .finally(() => {
              setLoading(false);
            });
    }
  };
  const deleteClick = () => {
    if (reasons.forDelete === '') {
      dispatch(setOpen('Please enter a reason for delete'));
      return;
    }
    setLoading(true);
    isUser
      ? deleteUser({ userId: id.toString(), reason: reasons.forDelete })
          .then(() => {
            dispatch(setOpen('User deleted successfully'));
            setReasons({ ...reasons, forDelete: '' });
            dispatch(updateUser({ userId: id.toString(), deleted: true }));
          })
          .catch((err) => {
            dispatch(setOpen(err.message || err.errors?.[0]));
          })
          .finally(() => {
            setLoading(false);
          })
      : deleteQuilt({ quiltId: id.toString(), reason: reasons.forDelete })
          .then(() => {
            dispatch(setOpen('Quilt deleted successfully'));
            setReasons({ ...reasons, forDelete: '' });
            userId
              ? dispatch(
                  updateUserQuilt({ userId: userId, quilt: { id: Number(id), deleted: true } })
                )
              : dispatch(updateQuilt({ id: Number(id), deleted: true }));
          })
          .catch((err) => {
            dispatch(setOpen(err.message || err.errors?.[0]));
          })
          .finally(() => {
            setLoading(false);
          });
  };

  return (
    <div className={styles.textarea_wrapper}>
      <div className={styles.textarea_box}>
        <div className="inputWithTitle">
          <span style={row.locked || row.deleted ? { opacity: 0.6 } : {}}>
            Reason for Lock
            <span className="requiredSymbol" style={{ fontSize: 24, top: '-2px' }}>
              *
            </span>
          </span>
          <TextField
            className="customInput"
            disabled={row.deleted || row.locked}
            value={reasons.forLock}
            onChange={({ target }) => {
              setReasons({ ...reasons, forLock: target.value });
            }}
            variant="outlined"
            placeholder={
              row.deleted
                ? row.reasonForLock
                : row.locked
                  ? 'Enter reason for unlock'
                  : 'Enter reason for lock'
            }
            multiline
            minRows={3}
          />
        </div>
        <button
          disabled={row.deleted}
          className={`customButton ${styles.adminBtn}`}
          onClick={lockClick}
        >
          {row.locked ? 'Unlock' : 'Lock'}
        </button>
      </div>
      <div className={styles.textarea_box}>
        <div className="inputWithTitle">
          <span style={row.deleted ? { opacity: 0.6 } : {}}>
            Reason for Delete
            <span className="requiredSymbol" style={{ fontSize: 24, top: '-2px' }}>
              *
            </span>
          </span>
          <TextField
            className="customInput"
            disabled={row.deleted}
            value={reasons.forDelete}
            onChange={({ target }) => {
              setReasons({ ...reasons, forDelete: target.value });
            }}
            variant="outlined"
            placeholder={row.deleted ? row.reasonForDelete : 'Enter reason for delete'}
            multiline
            minRows={3}
          />
        </div>
        <button
          disabled={row.deleted}
          className={`customButton ${styles.adminBtn}`}
          onClick={deleteClick}
        >
          Delete
        </button>
      </div>
      <Loader loading={loading} />
    </div>
  );
};
