import { OptionsRowType } from '../components/HomeCarousel/components/Row/RowDesk';
import { OptionsWrapEnum } from '../components/HomeCarousel';

interface UseSettingsRowPropsModel {
  rowId: string;
  option: OptionsWrapEnum;
}

interface UseSettingsRowModel {
  settings: OptionsRowType[];
}

export const useSettingsRow = ({
  rowId,
  option,
}: UseSettingsRowPropsModel): UseSettingsRowModel => {
  const settings: OptionsRowType[] = [];

  const settingsRowL: Record<string, OptionsRowType[]> = {
    0: [
      OptionsRowType.Type,
      OptionsRowType.Type1,
      OptionsRowType.Type,
      OptionsRowType.Type,
      OptionsRowType.Type1,
      OptionsRowType.Type,
    ],
    1: [
      OptionsRowType.Type1,
      OptionsRowType.Type,
      OptionsRowType.Type1,
      OptionsRowType.Stub,
      OptionsRowType.Type1,
      OptionsRowType.Type,
      OptionsRowType.Type1,
    ],
    2: [
      OptionsRowType.Type1,
      OptionsRowType.Type,
      OptionsRowType.Stub,
      OptionsRowType.Stub,
      OptionsRowType.Type,
      OptionsRowType.Type1,
    ],
    3: [
      OptionsRowType.Type,
      OptionsRowType.Type1,
      OptionsRowType.Type,
      OptionsRowType.Stub,
      OptionsRowType.Type,
      OptionsRowType.Type1,
      OptionsRowType.Type,
    ],
    4: [
      OptionsRowType.Type1,
      OptionsRowType.Type,
      OptionsRowType.Type1,
      OptionsRowType.Type1,
      OptionsRowType.Type,
      OptionsRowType.Type1,
    ],
  };

  const settingsRowM: Record<string, OptionsRowType[]> = {
    0: [OptionsRowType.Type1, OptionsRowType.Type, OptionsRowType.Type, OptionsRowType.Type1],
    1: [
      OptionsRowType.Type,
      OptionsRowType.Type1,
      OptionsRowType.Stub,
      OptionsRowType.Type1,
      OptionsRowType.Type,
    ],
    2: [OptionsRowType.Type, OptionsRowType.Stub, OptionsRowType.Stub, OptionsRowType.Type],
    3: [
      OptionsRowType.Type1,
      OptionsRowType.Type,
      OptionsRowType.Stub,
      OptionsRowType.Type,
      OptionsRowType.Type1,
    ],
    4: [OptionsRowType.Type, OptionsRowType.Type1, OptionsRowType.Type1, OptionsRowType.Type],
  };

  const settingsRowS: Record<string, OptionsRowType[]> = {
    0: [OptionsRowType.Type1, OptionsRowType.Type, OptionsRowType.Type, OptionsRowType.Type1],
    1: [OptionsRowType.Type1, OptionsRowType.Stub, OptionsRowType.Type1],
    2: [OptionsRowType.Type, OptionsRowType.Stub, OptionsRowType.Stub, OptionsRowType.Type],
    3: [OptionsRowType.Type, OptionsRowType.Stub, OptionsRowType.Type],
    4: [OptionsRowType.Type, OptionsRowType.Type1, OptionsRowType.Type1, OptionsRowType.Type],
  };

  if (option === OptionsWrapEnum.l) {
    settings.push(...(settingsRowL?.[rowId] ? settingsRowL[rowId] : []));
  }

  if (option === OptionsWrapEnum.m) {
    settings.push(...(settingsRowM?.[rowId] ? settingsRowM[rowId] : []));
  }

  if (option === OptionsWrapEnum.s) {
    settings.push(...(settingsRowS?.[rowId] ? settingsRowS[rowId] : []));
  }

  return {
    settings,
  };
};
