import { createSlice, PayloadAction } from '@reduxjs/toolkit';

interface NotifyStateModel {
  message: string;
  isOpen: boolean;
  isSave: boolean;
}

const initialState: NotifyStateModel = {
  message: '',
  isOpen: false,
  isSave: true,
};

const notifySlice = createSlice({
  name: 'notify',
  initialState,
  reducers: {
    setOpen: (state, action: PayloadAction<string>) => {
      state.isOpen = true;
      state.message = action.payload || 'Something went wrong. Please try again later.';
    },

    setClose: (state) => {
      state.isOpen = false;
      // state.message = '';
    },

    setIsSave: (state, action: PayloadAction<boolean>) => {
      state.isSave = action.payload
    },
  },
});

export const { setOpen, setClose, setIsSave } = notifySlice.actions;

export default notifySlice;
