import { FC, ReactElement } from 'react';
import { useLocation, Navigate } from 'react-router-dom';
import { useAuth } from '../providers/auth-provider';

interface RequireAuthProps {
  children: ReactElement<any, any> | null;
}

const RequireAuth: FC<RequireAuthProps> = ({ children }: RequireAuthProps) => {
  const location = useLocation();
  const { isAuthorized } = useAuth();

  if (location.pathname.includes("update-invite")) return children;

  if (!isAuthorized) return <Navigate to="/login" state={{ from: location }} />;

  return children;
};

export default RequireAuth;
