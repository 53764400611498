import React from 'react';
import { Box } from '@mui/material';

const LineBlock: React.FC = () => {
  return (
    <Box
      sx={{
        minHeight: '8px',
        background: "url('/img/general/line.png') no-repeat 100% / 100% 100%",
      }}
    />
  );
};
export default LineBlock;
