import { createSlice, PayloadAction } from '@reduxjs/toolkit';
import { AdminUserQuilt, AdminUserValue } from 'api/http-client/types';

const initialState: AdminUserValue[] = [];

const userAdminSlice = createSlice({
  name: 'adminUser',
  initialState,
  reducers: {
    setUsers: (state, action: PayloadAction<AdminUserValue[]>) => {
      return action.payload;
    },
    updateUser: (state, action: PayloadAction<Partial<AdminUserValue>>) => {
      const index = state.findIndex((user) => user.userId === action.payload.userId);
      if (index !== -1) {
        state[index] = { ...state[index], ...action.payload };
      }
    },
    updateUserQuilt: (
      state,
      action: PayloadAction<{ userId: string; quilt: Partial<AdminUserQuilt> }>
    ) => {
      const userIndex = state.findIndex((user) => user.userId === action.payload.userId);
      if (userIndex !== -1) {
        const quiltIndex = state[userIndex].quilts.findIndex(
          (quilt) => quilt.id === action.payload.quilt.id
        );
        if (quiltIndex !== -1) {
          state[userIndex].quilts[quiltIndex] = {
            ...state[userIndex].quilts[quiltIndex],
            ...action.payload.quilt,
          };
        }
      }
    },
    removeUsers: () => {
      return initialState;
    },
  },
});

export const { setUsers, updateUser, updateUserQuilt, removeUsers } = userAdminSlice.actions;

export default userAdminSlice;
