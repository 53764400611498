import React from 'react';

import styles from './resources.module.scss';

const Resources: React.FC = () => {
  const discussionForumsLink = "https://www.virtualhospice.ca/en_US/Main+Site+Navigation/Home/Support/Support/Discussion+Forums.aspx";
  const askProfessional = "https://www.virtualhospice.ca/en_US/Main+Site+Navigation/Home/Support/Support/Ask+a+Professional.aspx";
  const localMentalHealth = "https://www.canada.ca/en/public-health/services/mental-health-services/mental-health-get-help.html";
  const wwwNumbersCa = "https://988.ca";

  return (
    <section className="center resources" style={{
      paddingBottom: '15px'
    }}>
      <h2 className={styles.resourcesBigTitle}>Resources</h2>
      <p>
        Everyone’s grief experience is unique, and creating a Healing Quilt may evoke strong emotions. 
        You may wish to take a break (you can save your progress and return later), or speak with a trusted 
        family member, friend, colleague, or community member. The resources listed below may also be of support 
        as you navigate your grief. 
      </p>

      <div className={styles.line} />

      <h2 className={styles.resourcesBigTitle}>Grief support</h2>

      <p>
        You are not alone. Canadian Virtual Hospice provides online{' '} 
        <a 
          className={styles.http_link} 
          href={discussionForumsLink} 
          target={'_blank'}
        >Discussion Forums</a>{' '} 
        where you can connect with others who are living with loss and grieving. You can share, you can vent, and you will find understanding 
        and support. Our community has been described as “a hug by a warm blanket.”
      </p>

      <p>
        You can also connect with a health professional by selecting our{' '} 
        <a 
          className={styles.http_link}
          href={askProfessional}
          target={'_blank'}
        >Ask a Professional</a>{' '} 
        feature to pose a confidential question. A member of the Canadian Virtual Hospice interdisciplinary clinical team will respond within three 
        business days (not including Canadian statutory holidays).
      </p>

      <div className={styles.line} />

      <div className={styles.image_block}>
        <img className={styles.image} src="/img/general/myGrief.ca.jpg" alt="Resources" />
        <div className={styles.text_block}>
          <a 
              href="https://www.mygrief.ca"
              target='_blank'
          >
            <h4 className="mt-0 defaultTitle" style={{ marginBottom: '15px'}}>
              MyGrief.ca
            </h4>
          </a>
          <p className="my-20">
            Confidential and free, MyGrief.ca helps you to understand and move through your grief.
          </p>
          <ul>
            <li className={styles.list_item}>
              <span className={`${styles.list_dot} ${styles['no-indent-dot']}`}></span>In your own place, at your own pace
            </li>
            <li className={styles.list_item}>
              <span className={`${styles.list_dot} ${styles['no-indent-dot']}`}></span>Developed by people who have "been there" and
              and grief specialists
            </li>
            <li className={styles.list_item}>
              <span className={`${styles.list_dot} ${styles['no-indent-dot']}`}></span>Features "real life" stories and experiences
              in their life is dying or has died. It equips parents with the words and confidence needed to help children grieve life’s losses in healthy ways.
            </li>
          </ul>
        </div>
      </div>
      <div className={styles.line} />
      <div className={styles.image_block}>
        <img className={styles.image} src="/img/general/kidsGrief.ca.jpg" alt="Resources" />
        <div className={styles.text_block}>
          <a 
            href="https://www.kidsgrief.ca"
            target='_blank'
          >
            <h4 className="mt-0 defaultTitle" style={{ marginBottom: '15px'}}>
              KidsGrief.ca
            </h4>
          </a>
          <p className="my-20">
            KidsGrief.ca is a free online resource that helps parents support their children when someone 
            in their life is dying or has died. It equips parents with the words and confidence needed to help children 
            grieve life’s losses in healthy ways.
          </p>
        </div>
      </div>

      <div className={styles.line} />

      <h2 className="bigTitle">For First Nations, Inuit, and Métis Peoples</h2>
      <h4 className="my-30 defaultTitle1">Hope for Wellness Help Line</h4>
      <ul>
        <li className={styles.list_item}>
          <span className={styles.list_dot}></span>Call 1-855-242-3310 (toll-free) or connect to the
          online &nbsp;
          <a
            className={styles.http_link}
            href="https://www.hopeforwellness.ca"
            target="_blank"
            rel="noreferrer"
          >
            Hope for Wellness chat
          </a>
          .
        </li>
        <li className={styles.list_item}>
          <span className={styles.list_dot}></span>Available 24 hours a day, 7 days a week to First Nations, Inuit, 
          and Métis Peoples seeking emotional support, crisis intervention, or referrals to community-based services.
        </li>
        <li className={styles.list_item}>
          <span className={styles.list_dot}></span>Support is available in English and French and, 
          by request, in Cree, Ojibway, and Inuktitut.
        </li>
      </ul>
      <h4 className="my-30 defaultTitle1">The National Indian Residential School Crisis Line</h4>
      <p className="my-20">
        Crisis support is available to former Indian Residential School students and their families
        24 hours a day, 7 days a week at 1-866-925-4419 (toll-free).
      </p>
      <h4 className="my-30 defaultTitle1">
        Missing and Murdered Indigenous Women and Girls Crisis Line
      </h4>
      <p className="my-20">
        Crisis support is available to individuals impacted by the issue of missing and murdered
        Indigenous women, girls, and 2SLGBTQQIA+ people 24 hours a day, 7 days a week at
        1-844-413-6649 (toll-free).
      </p>

      <div className={styles.line} />

      <h2 className="bigTitle">Mental health and crisis resources</h2>
      <h4 className="my-30 defaultTitle1">Provincial and Territorial Mental Health Resources</h4>
      <h4>Find{' '} 
          <a 
            className={styles.http_link} 
            href={localMentalHealth}
            target={'_blank'}
          >local mental health</a>{' '}  
          resources and supports within your province or territory.
        </h4>

      <div className={styles.extra_info}>
        <span>9-8-8 Suicide Crisis Helpline</span>
      </div>
      <ul>
        <li className={styles.list_item}>
          <span className={styles.list_dot}></span>Call or text 9-8-8, 24/7 or visit 
          <a 
            className={styles.http_link} 
            href={wwwNumbersCa} 
            target={'_blank'}
            style={{
              marginLeft: '8px'
            }}
          >988.ca</a>.
        </li>
        <li className={styles.list_item}>
          <span className={styles.list_dot}></span>Support for immediate crisis or suicide-related concerns.
        </li>
        <li className={styles.list_item}>
          <span className={styles.list_dot}></span>Culturally appropriate and trauma-informed support available to anyone in Canada in English and French.
        </li>
      </ul>
      {/*<h4 className="my-30 defaultTitle1">The National Indian Residential School Crisis Line</h4>*/}
      {/*<p className="my-20">*/}
      {/*  Crisis support is available to former Indian Residential School students and their families*/}
      {/*  24 hours a day, 7 days a week at 1-866-925-4419 (toll-free).*/}
      {/*</p>*/}
      {/*<h4 className="my-30 defaultTitle1">*/}
      {/*  Missing and Murdered Indigenous Women and Girls Crisis Line*/}
      {/*</h4>*/}
      {/*<p className="my-20">*/}
      {/*  Crisis support is available to individuals impacted by the issue of missing and murdered*/}
      {/*  Indigenous women, girls, and 2SLGBTQQIA+ people 24 hours a day, 7 days a week at*/}
      {/*  1-844-413-6649 (toll-free).*/}
      {/*</p>*/}

    </section>
  );
};

export default Resources;
