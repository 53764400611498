import { useNavigate } from 'react-router-dom';
import {useAppDispatch, useAppSelector} from "./hooks";
import {showDialog} from "../store/reducers/dialogsSlice";
import {keySessionStorageSaveRoute} from "../utilities";

// We use only this hook in project! Don`t use useNavigate!

// isCanDirect - can we direct or no
// triggerPermissionRedirect - for invoke action if direct no

interface UseRedirectAndScrollParamsModel {
  isCanDirect?: boolean;
  triggerPermissionRedirect?: () => void
}

interface RedirectConfigModel {
  forceNavigate: boolean
}

export const useRedirectAndScroll = ({ isCanDirect, triggerPermissionRedirect } : UseRedirectAndScrollParamsModel) => {
  const navigate = useNavigate();
  const isSave = useAppSelector(state => state.notify.isSave)
  const dispatch = useAppDispatch()

  const handlerDirect = (path: string, replace?: boolean, config?: RedirectConfigModel) => {
    if (config && config.forceNavigate) {
      navigate(path, {
        replace
      });
      return;
    }

    // pattern with custom trigger
    if (isCanDirect) {
      navigate(path, {
        replace
      });
      return
    } else if (isCanDirect === false) {
      triggerPermissionRedirect && triggerPermissionRedirect()
      return
    }

    // check IsSave (default true but if we have logic on some pages we can control it)
    if (isSave) {
      navigate(path);
    } else {
      dispatch(showDialog("save"))
      sessionStorage.setItem(keySessionStorageSaveRoute, path)
    }
  }

  const redirectAndScroll = (path: string, id?: string, replace?: boolean, config?: RedirectConfigModel) => {
    handlerDirect(path, replace, config)

    if (id) onlyScroll(id)
    else window.scrollTo(0, 0);
  };

  const onlyScroll = (id: string) => {
    const element = document.getElementById(id);

    if (element) {
      element.scrollIntoView({ behavior: 'smooth' });
    }
  };

  const onlyRedirect = (path: string, replace?: boolean, config?: RedirectConfigModel) => {
    handlerDirect(path, replace, config)
  };

  return { redirectAndScroll, onlyScroll, onlyRedirect };
};
