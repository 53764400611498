import { PropsWithChildren, useState } from 'react';
import { TranslatesContext } from './context';

const LOCAL_STORAGE_KEY = 'cvh-lang';

export const getLangFromLocalStorage = () => {
  let storageItem = localStorage.getItem(LOCAL_STORAGE_KEY);
  if (storageItem !== 'en-CA' && storageItem !== 'fr-CA') storageItem = 'en-CA';
  return storageItem;
};

export const obtainTransl = (translResponse: any) => {
  const converted: any = {};
  const { localizations, ...enTranslates } = translResponse;
  converted['en-CA'] = enTranslates;
  converted['fr-CA'] = (translResponse.localizations?.data || [])[0]?.attributes;
  return converted;
};

const setLangToLocalStorage = (lang: string) => {
  localStorage.setItem(LOCAL_STORAGE_KEY, lang);
};

interface Props {}

export const TranslatesProvider = (props: PropsWithChildren<Props>) => {
  const [selectedLang, setSelectedLang] = useState(getLangFromLocalStorage());
  const [translates, setTranslates] = useState();

  const setLang = (lang: string) => {
    setSelectedLang(lang);
    setLangToLocalStorage(lang);
  };

  return (
    <TranslatesContext.Provider
      value={{
        selectedLang,
        translates: (translates || {})[selectedLang],
        setTranslates: (translates: any) => setTranslates(obtainTransl(translates)),
        setSelectedLang: (lang: string) => setLang(lang),
      }}
    >
      {props.children}
    </TranslatesContext.Provider>
  );
};
