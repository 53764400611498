import { TextField, TextFieldProps } from "@mui/material";
import styles from './styles.module.scss';
import { useRef, useState } from "react";

type Props = {
    params: TextFieldProps;
    value: Date | null;
};

const TextFieldWithCustomPlaceholder: React.FC<Props> = ({
    params,
    value
}) => {
    return (
        <div className={styles.customPlaceholderWrapper}>
            <TextField
                className="datePickerInput customSvgIconDatePicker"
                {...params}
                inputProps={{
                    ...params.inputProps,
                    placeholder: 'yyyy',
                }}
                sx={{width: '100%'}}
            />
            {value === null && <span className={styles.redAsteriks}>*</span>}
        </div>
    );
};

export { TextFieldWithCustomPlaceholder };
