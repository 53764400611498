import React, { FC, useEffect, useState } from 'react';

import { CarouselItem } from '../CarouselItemType/CarouselItem';
import { QuiltsResponseMega } from '../../../../api/http-client/types';
import { CarouselItemType } from '../CarouselItemType/CarouselItemType';
import { CarouselItemType1 } from '../CarouselItemType/CarouselItemType1';
import { DataSettingModel } from '../../../../hooks/useCarouselHome';
import { OptionsWrapEnum } from '../../index';
import { useSettingsRow } from '../../../../hooks/useSettingsRow';
import { useMediaQuery } from '@mui/material';

interface RowDeskPropsModel {
  indexOfRenderRow: number;
  items: QuiltsResponseMega[];
  settingData: DataSettingModel;
  option: OptionsWrapEnum;
}

export enum OptionsRowType {
  Stub = 'stub',
  Type = 'type',
  Type1 = 'type1',
}

export const RowDesk: FC<RowDeskPropsModel> = ({
  indexOfRenderRow,
  items,
  settingData,
  option,
}) => {
  const matches1450 = useMediaQuery('(max-width: 1450px)');

  const { settings } = useSettingsRow({
    rowId: `${indexOfRenderRow}`,
    option,
  });

  const [settingItems, setSettingItems] = useState<Record<string, OptionsRowType>>({});

  useEffect(() => {
    const settingsItems = settings.reduce(
      (total, item, index) => {
        total[`${index}`] = item;

        return total;
      },
      {} as Record<string, OptionsRowType>
    );

    setSettingItems(settingsItems);
  }, [option]);
  console.log('update');
  return (
    <CarouselItem>
      {items.map((item, index) => {
        return (
          <React.Fragment key={index}>
            {settingItems[index] === OptionsRowType.Stub && (
              <CarouselItemType isStub></CarouselItemType>
            )}

            {(matches1450 ? indexOfRenderRow % 2 !== 0 : indexOfRenderRow % 2 === 0) && (
              <CarouselItemType isStub></CarouselItemType>
            )}
            {settingItems[index] === OptionsRowType.Type ? (
              <CarouselItemType item={item}></CarouselItemType>
            ) : settingItems[index] === OptionsRowType.Type1 ? (
              <CarouselItemType1 item={item}></CarouselItemType1>
            ) : null}
            {(matches1450 ? indexOfRenderRow % 2 === 0 : indexOfRenderRow % 2 !== 0) && (
              <CarouselItemType isStub></CarouselItemType>
            )}
          </React.Fragment>
        );
      })}
    </CarouselItem>
  );
};
