import {BlockModel, TDeletingBlock, TSideBlock, TSideChangePositions } from "./model";
import RichTextEditor from "../../RichTextEditor";
import styles from "./styles.module.scss";
// import {styleImageByType} from "./const";
import React, {FC} from "react";
import {styleImageByType} from "./const";
import DeleteOutlineIcon from '@mui/icons-material/DeleteOutline';
import EditIcon from '@mui/icons-material/Edit';

interface BlockImageOrTextFullProps {
	block : BlockModel
	setEditorDataById: (id: string, newValue: string, side?: TSideBlock) => void;
	setConfirmDeleting: React.Dispatch<React.SetStateAction<{openModal: boolean;side?: TSideBlock | undefined;block: BlockModel;typeDeleting: TDeletingBlock}>>
	updateBlockImage: (block: BlockModel) => void
	changePositionBlocks: (id: string, side: TSideBlock, idTo: string, sideTo: TSideBlock) => void
}

export const BlockImageOrTextFull : FC<BlockImageOrTextFullProps> = ({ block,setConfirmDeleting,updateBlockImage, setEditorDataById, changePositionBlocks }) => {
	const blockDragOver = (event: any) => event.preventDefault()

	const startMoveBlock = (event: any, id: string, side: TSideChangePositions) => {
		if (!event?.dataTransfer) return;

		const transferData = JSON.stringify({
			id,
			side,
		})

		event.dataTransfer.setData('application/json', transferData);
	}

	const blockDrop = (event: any, idTo: string) => {
		event.preventDefault();

		const transferData = JSON.parse(event.dataTransfer.getData('application/json'));
		const toSide = event.currentTarget.dataset.side;
		const fromSide = transferData.side

		changePositionBlocks(transferData?.id || '', fromSide, idTo, toSide)
	}

	return (
		<div
			onDragStart={(e) => startMoveBlock(e, block.id, 'full')} 
			onDrop={(e) => blockDrop(e, block.id)} 
			onDragOver={blockDragOver} 
			draggable={true}
			data-side="full" 
		>
			{
				(block.position === 'full' && typeof block.editorData === 'string') ? (
					<div className={`${styles.textWrapper} editorBlock`}>
						<div className={styles.actionButtons}  onClick={() => {setConfirmDeleting({openModal:true,block,typeDeleting:'editor'})}}>
							<div className={styles.details__addPhotoActionSvg}>{<DeleteOutlineIcon />}</div>
						</div>
						<RichTextEditor editorData={block.editorData} setEditorData={(value) => setEditorDataById(block.id, value)} quiltId={''} height={400}></RichTextEditor>
					</div>
				) : null
			}

			{
				(block.position === 'full' &&  block.image &&  'file' in block.image) ? (
					<div className={styles.editorBlock__imageFull}>
						<div className={styles.imgWrapper}>
							<img
								src={block.image.file}
								alt="picture"
								className={styleImageByType[block.image.type]}
							/>
							<div className={styles.actionButtons}>
								<div className={styles.details__addPhotoActionSvg} onClick={()=>{updateBlockImage(block)}}>
									<EditIcon />
								</div>
								<div className={styles.details__addPhotoActionSvg} onClick={() => {setConfirmDeleting({openModal:true,block,typeDeleting:'image'})}}>
									<DeleteOutlineIcon />
								</div>
							</div>
						</div>
					</div>
				) : null
			}
		</div>
	)
}
