import { FC } from 'react';
import { Route, Routes } from 'react-router-dom';
import RequireAuth from '../../hoc/RequireAuth';
import { useTranslates } from '../../providers/translates';
import { routs } from '../../routs';
import Layout from '../Layout';

interface AppRouterProps {}

const AppRouter: FC<AppRouterProps> = () => {
  const { translates } = useTranslates();

  return (
    <>
      <Routes>
        <Route path="/" element={<Layout />}>
          {routs.map((route) =>
            route.isPrivate ? (
              <Route
                key={route.path}
                path={route.path}
                element={
                  <RequireAuth>
                    <route.element />
                  </RequireAuth>
                }
              />
            ) : (
              <Route key={route.path} path={route.path} element={<route.element />} />
            )
          )}
          <Route
            path="*"
            element={
              <div style={{ textAlign: 'center' }}>
                {translates?.pageNotFound || 'Page not found'}
              </div>
            }
          />
        </Route>
      </Routes>
    </>
  );
};

export default AppRouter;
