import { ChangeEvent, forwardRef, useCallback, useEffect, useRef, useState, Ref, Dispatch, SetStateAction } from 'react';
import { Select, SelectChangeEvent, SvgIcon } from '@mui/material';
import SettingsBackupRestoreIcon from '@mui/icons-material/SettingsBackupRestore';
import ArrowDropDownIcon from '@mui/icons-material/ArrowDropDown';

import { compareFiles, getSelectOptions } from '../../../utilities';
import { aspectImageByType, optionsTypeImages, TypeImageEnum } from '../CreateMemorialQuiltStepThree/const';
import { ImageTypeModel } from '../CreateMemorialQuiltStepThree/model';
import { DefaultDialog } from '../../DefaultDialog';
import { accessImageDialog } from '../../../utilities/dialogs';
import DeleteOutlineIcon from '@mui/icons-material/DeleteOutline';

import Cropper from 'cropperjs';
import 'cropperjs/dist/cropper.css';

import styles from '../CreateMemorialQuiltStepThree/styles.module.scss';
import { useLocation } from 'react-router-dom';
import { setOpen } from 'store/reducers/notifySlice';
import {useAppDispatch, useRedirectAndScroll} from 'hooks';

interface CreateImagesBlockLocalProps {
  setImageBlock: (image: ImageTypeModel | null) => void;
  isAddImagesOnly?: boolean;
  isStepOne?: boolean;
  setFilesImages: Dispatch<SetStateAction<File[]>>;
  filesImages: File[];
}

export const CreateImagesBlockLocal = forwardRef<HTMLDivElement, CreateImagesBlockLocalProps>(
  (
    { setImageBlock, isAddImagesOnly, isStepOne, setFilesImages, filesImages },
    ref: Ref<HTMLDivElement>
  ) => {
    const location = useLocation()
    const { onlyRedirect } = useRedirectAndScroll({})
    const [currentFileImage, setCurrentFileImage] = useState<File | null>();
    const [currentCrop, setCurrentCrop] = useState<Cropper | null>(null);
    const [aspectRatio, setAspectRatio] = useState<TypeImageEnum>(
      isStepOne ? TypeImageEnum.SQUARE : TypeImageEnum.PORTRAIT
    );
    const [confirmChooseImageDialog, setConfirmChooseImageDialog] = useState<boolean>(false);
    const [showClearImagesDialog, setShowClearImagesDialog] = useState<boolean>(false);
    const [isNotAskAccessPermission, setIsNotAskAccessPermission] = useState<boolean>(false);

    const fileInputRef = useRef<HTMLInputElement>(null);
    const imageRef = useRef<HTMLImageElement>(null);
    const dispatch = useAppDispatch();

    useEffect(() => {
      if (!currentCrop) return;

      currentCrop.setAspectRatio(aspectImageByType[aspectRatio]);
    }, [aspectRatio]);

    useEffect(() => {
      if (!imageRef.current) return;

      if (currentCrop && currentFileImage) {
        currentCrop.replace(URL.createObjectURL(currentFileImage));
      } else {
        setCurrentCrop(
          new Cropper(imageRef.current, {
            aspectRatio: aspectImageByType[aspectRatio],
          })
        );
      }
    }, [imageRef, currentFileImage]);

    const isAddImages = () => location.pathname.includes("add-images")

    // need for opening a window for pictures
    const openImageChoosing = () => {
      setConfirmChooseImageDialog(false);
      setIsNotAskAccessPermission(true);
      fileInputRef?.current && fileInputRef?.current?.click();
    };

    const changeImages = async (event: ChangeEvent<HTMLInputElement>) => {
      const files = event.target.files;

      if (!files) return;

      // check the same images
      const filesDataUnique: File[] = []

      if (filesImages.length === 0) {
        filesDataUnique.push(...Array.from(files))
      } else {
        for (const newFile of Array.from(files)) {
          let isNewFile = true;

          for (const wasFile of filesImages) {
            const isSameFile = await compareFiles(newFile, wasFile)

            if (isSameFile) {
              dispatch(setOpen(`You already have the same picture! You can\`t add ${newFile.name}`))
              isNewFile = false;
              break;
            }
          }

          if (isNewFile) filesDataUnique.push(newFile)
        }
      }

      setFilesImages((state => {
        return [
          ...state,
          ...filesDataUnique
        ]
      }));
    };

    const resetImages = () => {
      setFilesImages([]);
      setShowClearImagesDialog(false);
    };

    const deleteFileImage = async (fileIndex: number) => {
      // for local delete
      setFilesImages(state => state ? state.filter((item, index) => index !== fileIndex) : state)
    }

    // drag
    const imageDragOver = (event: any) => event.preventDefault();

    const imageDragStart = (event: any, index: number) => {
      if (!event?.dataTransfer) return;

      event.dataTransfer.setData('text/plain', index);
    };

    const imageDrop = (event: any) => {
      event.preventDefault();

      const file = filesImages[event.dataTransfer.getData('text')];

      file && setCurrentFileImage(file);
    };

    // select
    const getOptionsTypeImages = useCallback(
      (typeImage: string) => {
        return getSelectOptions(optionsTypeImages, typeImage);
      },
      [currentFileImage]
    );

    const changeCurrentFileImageType = (event: SelectChangeEvent<TypeImageEnum>) => {
      setAspectRatio(event.target.value as TypeImageEnum);
    };

    // image actions
    const changeImage = () => {
      setCurrentFileImage(null);
      setCurrentCrop(null);

      currentCrop?.destroy();
    };

    const cancelImage = () => {
      changeImage();
      setImageBlock(null);
    };

    const continueImage = () => {
      if (!currentCrop) return;

      setImageBlock({
        file: currentCrop.getCroppedCanvas().toDataURL('image/png'),
        type: aspectRatio,
      });

      changeImage();
    };

    const rotateImage = () => {
      currentCrop && currentCrop.rotate(45);
    };

    return (
      <>
        <DefaultDialog
          open={showClearImagesDialog}
          setDialog={setShowClearImagesDialog}
          title={'Clear images'}
          text={
            'Are you sure you want to clear the album? All photos will be deleted and this cannot be undone'
          }
          confirmAction={resetImages}
        />

        <div
          ref={ref}
          className={styles.editorBlock1}
          style={{
            ...(isAddImagesOnly && {
              height: '750px',
            }),
          }}
        >
          <div className={styles.editorBlock1__header}>
            <div className={styles.editorBlock1__headerLeft}>
              <input
                type="file"
                id="fileInputAddImage"
                multiple
                ref={fileInputRef}
                onChange={changeImages}
                accept="image/png, image/jpeg"
                style={{
                  display: 'none',
                }}
              />
              <button
                className={`${styles.editorBlock1__button}`}
                onClick={() => isNotAskAccessPermission ? openImageChoosing() : setConfirmChooseImageDialog(true)}
              >
                add images
              </button>
              <span className={styles.isDesktop}>Add your images to the album</span>
            </div>

            <button className={`${styles.editorBlock1__button}`} onClick={() => isAddImagesOnly ? onlyRedirect("/") : cancelImage()}>
              exit
            </button>
          </div>
          <span className={styles.isMobile}>Add your images to the album</span>

          <div className={styles.editorBlock1__middle}>
            <div
              className={styles.editorBlock1__middleLeft}
              style={{
                ...(isAddImagesOnly && {
                  minWidth: '100%',
                }),
              }}
            >
              {filesImages &&
                filesImages.map((file, index) => {
                  return (
                    <div key={index} className={styles.imgWrapper}>
                      <img
                        onDragStart={(event) => imageDragStart(event, index)}
                        src={URL.createObjectURL(file)}
                        alt={file.name}
                        key={index}
                        draggable={true}
                        onDoubleClick={() => setCurrentFileImage(file)}
                      />
                      {!isAddImages() && (
                        <div className={styles.actionButtons} onClick={() => deleteFileImage(index)}>
                          <div className={styles.details__addPhotoActionSvg}>
                            <DeleteOutlineIcon />
                          </div>
                       </div>
                      )}
                    </div>
                  );
                })}
            </div>


            {!isAddImagesOnly && (
              <>
                <div className={`${styles.editorBlock1__bottomButtonLeft} ${styles.isMobile}`}>
                  <button
                    className={`${styles.editorBlock1__button}`}
                    style={{
                      color: '#d85656',
                    }}
                    onClick={() => setShowClearImagesDialog(true)}
                  >
                    clear album
                  </button>
                </div>
                  <div
                    className={styles.editorBlock__drag}
                    onDrop={imageDrop}
                    onDragOver={imageDragOver}
                  >
                    {currentFileImage ? (
                      <img
                        ref={imageRef}
                        src={URL.createObjectURL(currentFileImage)}
                        alt={currentFileImage?.name}
                        style={{
                          display: 'block',
                          maxWidth: '100%',
                        }}
                      />
                    ) : (
                      <div className={styles.editorBlock__dragIcon}>
                        <img src="/img/general/drag-drop.png" alt="drag-drop" />
                        <span>Drag an image from the album to add here</span>
                      </div>
                    )}
                  </div>
              </>
            )}
          </div>

          {!isAddImagesOnly && (
            <div className={styles.editorBlock1__bottom}>
              <div className={`${styles.editorBlock1__bottomButtonLeft} ${styles.isDesktop}`}>
                <button
                  className={`${styles.editorBlock1__button}`}
                  style={{
                    color: '#d85656',
                  }}
                  onClick={() => setShowClearImagesDialog(true)}
                >
                  clear album
                </button>
              </div>
              <div className={styles.editorBlock1__bottomButtonRight}>
                <div className={`${styles.editorBlock1__bottomButton_choice} ${isStepOne ? '' : styles.column_reverse}`}>
                  <div className={styles.rotateAndSelectWrapper}>
                    <button
                      className={`${styles.editorBlock1__button}`}
                      style={{
                        padding: '6px 10px 3px',
                      }}
                    >
                      <SettingsBackupRestoreIcon
                        onClick={rotateImage}
                        className={`${styles.editorBlock1__bottomSvg}`}
                      ></SettingsBackupRestoreIcon>
                    </button>
                    {!isStepOne && (
                      <Select
                        className="customSelect"
                        IconComponent={() => (
                          <SvgIcon fontSize="large" component={ArrowDropDownIcon} />
                        )}
                        inputProps={{
                          sx: {
                            background: 'white',
                            padding: '5px 15px',
                            fontSize: '20px !important',
                          },
                        }}
                        sx={{
                          minWidth: { xs: 'auto', sm: 200 },
                          minHeight: 39,
                          fieldset: {
                            border: '1px solid #26a3c5',
                          },
                        }}
                        MenuProps={{
                          className: 'customSelectMenu',
                        }}
                        value={aspectRatio}
                        onChange={changeCurrentFileImageType}
                      >
                        {getOptionsTypeImages(aspectRatio || '')}
                      </Select>
                    )}
                  </div>
                  <button className={`${styles.editorBlock1__button}`} onClick={changeImage}>
                    Clear image
                  </button>
                </div>

                <div className={styles.editorBlock1__bottomButton}>
                  <button className={`${styles.editorBlock1__button}`} onClick={cancelImage}>
                    Cancel
                  </button>
                  <button
                    className={`${styles.editorBlock1__button}`}
                    onClick={continueImage}
                  >
                    Save
                  </button>
                </div>
              </div>
            </div>
          )}

          <DefaultDialog
            open={confirmChooseImageDialog}
            setDialog={setConfirmChooseImageDialog}
            title={accessImageDialog.title}
            text={accessImageDialog.text}
            confirmAction={openImageChoosing}
          ></DefaultDialog>
        </div>
      </>
    );
  }
);
