import React from 'react';
import { Box, Typography } from '@mui/material';

import { colors } from 'utilities';
import { useRedirectAndScroll } from 'hooks';
import { AccessPermissionsTextEnum } from 'components/CreateMemorialQuiltComponents/CreateMemorialQuiltStepTwo/const';
import { IQuiltTableContent } from '.';
import {InvitedUsers} from "../../../components/CreateMemorialQuiltComponents/components";

interface QuiltDetailsProps {
  row: IQuiltTableContent;
  userId?: string;
}

export const QuiltDetails: React.FC<QuiltDetailsProps> = ({ row, userId }) => {
  const { redirectAndScroll } = useRedirectAndScroll({});

  return (
    <Box>
      <Box sx={{ display: 'flex', gap: 6 }}>
        <Box sx={{ display: 'flex', gap: 6, width: '100%' }}>
          {row.finalImage ? (
            <img
              src={row.finalImage}
              alt={row.quiltFullName}
              style={{ width: '250px', height: '250px', objectFit: 'cover' }}
            />
          ) : (
            <Box
              sx={{
                width: '250px',
                height: '250px',
                backgroundColor: colors.grey,
                display: 'flex',
                justifyContent: 'center',
                alignItems: 'center',
                fontSize: 24,
                color: '#fff',
              }}
            >
              No Image
            </Box>
          )}
          <Box sx={{ display: 'flex', flexDirection: 'column', gap: 1 }}>
            <Typography sx={{ color: colors.dark_blue, fontSize: 22, fontWeight: 700 }}>
              {userId ? 'Quilt' : 'Quilt owner'}
            </Typography>
            {userId && (
              <>
                <Typography sx={{ color: colors.dark_blue, fontSize: 18, fontWeight: 700 }}>
                  Date created:{' '}
                  <Box component={'span'} sx={{ fontWeight: 400, color: '#000' }}>
                    {row.dateCreated}
                  </Box>
                </Typography>
                <Typography sx={{ color: colors.dark_blue, fontSize: 18, fontWeight: 700 }}>
                  Date published:{' '}
                  <Box component={'span'} sx={{ fontWeight: 400, color: '#000' }}>
                    {row.datePublished}
                  </Box>
                </Typography>
                <Typography sx={{ color: colors.dark_blue, fontSize: 18, fontWeight: 700 }}>
                  Public:{' '}
                  <Box component={'span'} sx={{ fontWeight: 400, color: '#000' }}>
                    {row.public}
                  </Box>
                </Typography>
              </>
            )}
            {row.owner?.userFullName && (
              <Typography sx={{ color: colors.dark_blue, fontSize: 18, fontWeight: 700 }}>
                Name:{' '}
                <Box component={'span'} sx={{ fontWeight: 400, color: '#000' }}>
                  {row.owner.userFullName}
                </Box>
              </Typography>
            )}
            {row.owner?.email && (
              <Typography sx={{ color: colors.dark_blue, fontSize: 18, fontWeight: 700 }}>
                Email:{' '}
                <Box
                  component={'a'}
                  href={`mailto:${row.owner.email}`}
                  sx={{
                    'fontWeight': 400,
                    'color': colors.blue,
                    'textDecoration': 'underline',
                    '&:hover': { color: colors.dark_blue },
                  }}
                >
                  {row.owner.email}
                </Box>
              </Typography>
            )}
            {row.owner && (
              <>
                <Typography sx={{ color: colors.dark_blue, fontSize: 18, fontWeight: 700 }}>
                  Locked:{' '}
                  <Box component={'span'} sx={{ fontWeight: 400, color: '#000' }}>
                    {row.owner.locked ? 'Yes' : 'No'}
                  </Box>
                </Typography>
                <Typography sx={{ color: colors.dark_blue, fontSize: 18, fontWeight: 700 }}>
                  Deleted:{' '}
                  <Box component={'span'} sx={{ fontWeight: 400, color: '#000' }}>
                    {row.owner.deleted ? 'Yes' : 'No'}
                  </Box>
                </Typography>
              </>
            )}
          </Box>
        </Box>
        <Box sx={{ display: 'flex', gap: 6, width: '100%' }}>
          <Box
            sx={{
              display: 'flex',
              justifyContent: 'space-between',
              width: '100%',
              gap: 6,
            }}
          >
            <Box sx={{ display: 'flex', flexDirection: 'column', gap: 1 }}>
              <Typography sx={{ color: colors.dark_blue, fontSize: 22, fontWeight: 700 }}>
                Invited users:
              </Typography>
              <Typography sx={{ color: colors.dark_blue, fontSize: 18, fontWeight: 700 }}>
                Full access:{' '}
                <Box component={'span'} sx={{ fontWeight: 400, color: '#000' }}>
                  {row.contributors.filter(
                    (contributor) =>
                      contributor.accessMode === AccessPermissionsTextEnum.EDIT_IMAGES_MEMORIAL
                  ).length || 'No one'}
                </Box>
              </Typography>

              <Box>
                {row.quiltId && <InvitedUsers isSmallBlock={true} idQuilt={`${row.quiltId}`}></InvitedUsers>}
              </Box>
            </Box>
            <Box sx={{ display: 'flex', gap: 6 }}>
              <Typography sx={{ color: colors.dark_blue, fontSize: 22, fontWeight: 700 }}>
                Locked:{' '}
                <Box component={'span'} sx={{ fontWeight: 400, color: '#000' }}>
                  {row.locked ? 'Yes' : 'No'}
                </Box>
              </Typography>
              <Typography sx={{ color: colors.dark_blue, fontSize: 22, fontWeight: 700 }}>
                Deleted:{' '}
                <Box component={'span'} sx={{ fontWeight: 400, color: '#000' }}>
                  {row.deleted ? 'Yes' : 'No'}
                </Box>
              </Typography>
            </Box>
          </Box>
        </Box>
      </Box>
      <Box
        sx={{
          'mt': 4,
          'mb': 4,
          'cursor': 'pointer',
          'width': 'fit-content',
          'fontSize': 20,
          'color': colors.blue,
          'textDecoration': 'underline',
          '&:hover': { color: colors.dark_blue },
        }}
        onClick={() => redirectAndScroll('/quilts/' + row.quiltId + '/memorial')}
      >
        Click here to go to the Quilt page
      </Box>
    </Box>
  );
};
