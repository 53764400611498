import React from 'react';
import { Backdrop, CircularProgress } from '@mui/material';

export const Loader: React.FC<{ loading: boolean; isWhite?: boolean }> = ({
  loading,
  isWhite = true,
}) => {
  return (
    <Backdrop
      sx={{ zIndex: (theme) => theme.zIndex.drawer + 1, backgroundColor: isWhite ? 'white' : '' }}
      open={loading}
    >
      <CircularProgress color="primary" />
    </Backdrop>
  );
};
