import { FC, useRef } from 'react';
import { Editor } from '@tinymce/tinymce-react';
import { Editor as EditorType } from 'tinymce';

import { useTranslates } from '../../providers/translates';
import { postMemorialEditorImg } from '../../api/http-client/queries/memorials';
import { notifyMessagesFileUpload, maxImgSize } from '../../utilities/constants';
import { setOpen } from 'store/reducers/notifySlice';
import { useAppDispatch } from 'hooks';

const RichTextEditor: FC<{
  setEditorData: (value: string) => void;
  quiltId: string;
  setNewTemplate?: (template: string) => void;
  editorData?: string;
  height?: number;
  disabled?: boolean;
}> = ({ editorData = '', setEditorData, quiltId, height = 800, disabled = false }) => {
  const { translates } = useTranslates();
  const editorRef = useRef<null | EditorType>(null);
  const dispatch = useAppDispatch();

  const setData = () => {
    if (editorRef.current) {
      // setNewTemplate&&setNewTemplate(editorRef.current.getContent());
      setEditorData(editorRef.current.getContent());
    }
  };

  return (
    <>
      <Editor
        disabled={disabled}
        tinymceScriptSrc={process.env.PUBLIC_URL + '/tinymce/tinymce.min.js'}
        apiKey={process.env.REACT_APP_TINYMCE_API_KEY}
        onInit={(evt, editor) => (editorRef.current = editor)}
        initialValue={editorData}
        init={{
          min_height: height,
          width: '100%',
          menubar: false,
          max_height: 650,
          mobile: {
            toolbar_mode: 'floating',
          },
          plugins: [
            'autolink',
            'link',
            'image',
            'lists',
            'charmap',
            'preview',
            'fullscreen',
            'insertdatetime',
            'media',
            'table',
            'emoticons',
            'help',
            'save',
            'quickbars',
          ],
          toolbar:
            'undo redo | blocks fontsize fontfamily | ' +
            'bold italic forecolor | alignleft aligncenter ' +
            'alignright | bullist | link emoticons',
          content_style: 'body { font-family:Helvetica,Arial,sans-serif; font-size:14px }',
          a11y_advanced_options: true,
          font_family_formats: 'Andale Mono=andale mono,times; Arial=arial,helvetica,sans-serif; Arial Black=arial black,avant garde; Helvetica=helvetica; Times New Roman=times new roman,times; Verdana=verdana,geneva;',
          font_size_formats: '10px 14px 18px 24px 36px',
          line_height_formats: '1 1.2 1.4 2',
          file_picker_types: 'image',
          images_file_types: 'jpeg,jpg,jpe,jfi,jif,jfif,png,gif,bmp,webp',
          paste_data_images: false,
          quickbars_insert_toolbar: '',
          quickbars_selection_toolbar: '',
          images_upload_handler: (blobInfo) => {
            return postMemorialEditorImg(quiltId, blobInfo.blob() as unknown as File)
              .then((resp) => {
                if (resp.data.success) {
                  return resp.data.returned.url;
                }
              })
              .catch((error) => console.log(error));
          },

          file_picker_callback: (callback, value, meta) => {
            // Provide file and text for the link dialog
            if (meta.filetype === 'file') {
              callback('mypage.html', { text: 'My text' });
            }

            // Provide image and alt text for the image dialog
            if (meta.filetype === 'image') {
              const input = document.createElement('input');
              input.setAttribute('type', 'file');
              input.setAttribute('accept', 'image/*');
              input.addEventListener('change', (e: React.ComponentState) => {
                const file = e.target.files[0];
                if (file.size > maxImgSize) {
                  dispatch(setOpen(
                    `${file?.file?.name} ${
                      translates?.notifyMessagesFileUpload?.tooBigFile ||
                      notifyMessagesFileUpload.tooBigFile
                    }`));
                  return;
                }
                const reader = new FileReader();
                reader.addEventListener('load', () => {
                  const id = 'blobid' + new Date().getTime();
                  if (editorRef.current) {
                    const blobCache = editorRef.current.editorUpload.blobCache;
                    const base64 = (reader.result as string).split(',')[1];
                    const blobInfo = blobCache.create(id, file, base64);
                    blobCache.add(blobInfo);
                    const url = blobInfo.blobUri();
                    /* call the callback and populate the Title field with the file name */

                    callback(url, { title: file.name });
                  }
                });
                reader.readAsDataURL(file);
              });

              input.click();
            }
            // Provide alternative source and posted for the media dialog
            if (meta.filetype === 'media') {
              callback('movie.mp4', { source2: 'alt.ogg', poster: 'image.jpg' });
            }
          },
          image_caption: true,
          image_advtab: true,
          image_dimensions: true,
        }}
        onBlur={setData}
      />
    </>
  );
};

export default RichTextEditor;
