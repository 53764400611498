import { createSlice, PayloadAction } from '@reduxjs/toolkit';

export type DialogsName = 'blocks' | 'save'

interface DialogsState {
  dialogsState: Record<DialogsName, boolean>
}

const initialState: DialogsState = {
  dialogsState: {
    blocks: false,
    save: false
  },
};

const dialogsSlice = createSlice({
  name: 'dialogs',
  initialState,
  reducers: {
    showDialog: (state, action: PayloadAction<DialogsName>) => {
      state.dialogsState[action.payload] = true
    },
    hideDialog: (state, action: PayloadAction<DialogsName>) => {
      state.dialogsState[action.payload] = false
    },
  },
});

export const { showDialog, hideDialog } = dialogsSlice.actions;

export default dialogsSlice;
